import { colors } from '../../constants/theme'

export const styles = {
    stylesForButton: {
        color: "white",
        cursor: "pointer",
        borderRadius: 15,
        backgroundColor: colors.button,
        height: "50px",
        width: '250px',
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'space-evenly',
        whiteSpace: 'nowrap'
    },
    stylesForHollowButton: {
        color: colors.button,
        cursor: "pointer",
        borderRadius: 15,
        border: `2px solid ${colors.button}`,
        backgroundColor: 'transparent',
        height: "50px",
        width: '250px',
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'space-evenly',
        whiteSpace: 'nowrap'
    }
}
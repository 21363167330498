import axios from "axios"
import {
    getAllAppSettingsURL,
    updateAppSettingsURL
} from "../../API/endpoints"

export function getaAppSettings(
    setId,
    setAppSettingsBody,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    axios.get(getAllAppSettingsURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data.length > 0) {
                    setAppSettingsBody(res.data.data[0])
                    setId(res.data.data[0]._id)
                }
                else {
                    setAppSettingsBody(null)
                    setId(null)
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get application settings at the moment.");
        })
}

export function updateAppSettings(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getAppSettingsData
) {
    setLoading(true)
    axios
        .put(updateAppSettingsURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Settings updated successfully !");
                getAppSettingsData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppSettingsData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update settings at the moment.");
            getAppSettingsData()
        });
}
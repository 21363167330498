import axios from "axios"
import {
    addStyleTypeURL, getAllStyleTypesURL,
    deleteStyleTypeURL, updateStyleTypeURL,
    getAppContentURL, addAppContentURL,
    updateAppContentURL, deleteAppContentURL
} from "../../API/endpoints"

export function addStyleType(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getStyleTypeData
) {
    setLoading(true)
    axios
        .post(addStyleTypeURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new style type has been added successfully !");
                getStyleTypeData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getStyleTypeData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new style type at the moment.");
            getStyleTypeData()
        });
}

export function getStyleType(
    setStyleTypeData,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllStyleTypesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setStyleTypeData(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get style types at the moment.");
        })
}

export function removeStyleType(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getStyleTypeData,
    onCloseConfirmDelete,
) {
    setLoading(true)
    axios.delete(deleteStyleTypeURL + "/" + e._id)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Style Type deleted successfully !`);
                getStyleTypeData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getStyleTypeData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this style type at the moment.");
            getStyleTypeData()
        })
}

export function updateStyleType(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getStyleTypeData,
) {
    setLoading(true)
    axios
        .put(updateStyleTypeURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Style type has been updated successfully !");
                getStyleTypeData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getStyleTypeData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update style type at the moment.");
            getStyleTypeData()
        });
}

// Get Application Content URLs
export function addAppContentForStyleType(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoadingAppContent,
    getAppContentData
) {
    setLoadingAppContent(true)
    axios
        .post(addAppContentURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Style type description has been added successfully !");
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add style type description at the moment.");
            getAppContentData()
        });
}

export function getAppContentForStyleType(
    setMode,
    setId,
    setStyleTypeBody,
    setLoadingAppContent,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAppContentURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data?.length > 0 && res.data.data[0]?.style_type) {
                    setMode('edit')
                    setId(res.data.data[0]._id)
                    setStyleTypeBody(res.data.data[0].style_type?.description ?? '')
                } else {
                    setMode('add')
                    setStyleTypeBody('')
                }
                setLoadingAppContent(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get style type description at the moment.");
        })
}

export function updateAppContentForStyleType(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoadingAppContent,
    getAppContentData
) {
    setLoadingAppContent(true)
    axios
        .put(updateAppContentURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Style Type description has been updated successfully !");
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Style Type description at the moment.");
            getAppContentData()
        });
}

export function removeAppContentForStyleType(
    id,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getAppContentData,
) {
    setLoading(true)
    axios.delete(deleteAppContentURL + "/style_type/" + id)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg(`Style Type description cleared successfully !`);
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot clear the description at the moment.");
            getAppContentData()
        })
}

import React, { useEffect, useState, useReducer, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogActions, MenuItem } from '@material-ui/core'
import ImageViewer from "react-simple-image-viewer";
import Button from '../Button/Button'
import HollowButton from '../HollowButton/HollowButton'
import InputField from '../InputField/InputField'
import { styles } from '../../styling/modal/modalStyle'
import ImageInput from '../ImageInput/ImageInput'
import MultipleImageInput from '../MultipleImageInput/MultipleImageInput'
import TextEditor from '../TextEditor/TextEditor';
import { uploadsUrl } from '../../API/endpoints';
import InputDropdown from '../InputDropdown';

function DetailsModal(props) {

  const initialState = {
    type: '',
    tagline: '',
    question: ''
  }

  function reducer(state, { field, value }) {
    return {
      ...state,
      [field]: value,
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    type,
    tagline,
    question
  } = state

  const [imageData, setImageData] = useState('') // for preview
  const [avatar, setAvatar] = useState('') // For storing the avatar data
  const [fullScreen, setFullScreen] = useState(false)
  const [multipleImageData, setMultipleImageData] = useState([]) // For Preview
  const [multipleImages, setMultipleImages] = useState([]) // For storing the images data
  const [paragraphTextBody, setParagraphTextBody] = useState('')
  // Image Viewer variables
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imagesDisplay, setImagesDisplay] = useState([]);
  // Body Shapes Description variables
  const [goalBody, setGoalBody] = useState('')
  const [rulesBody, setRulesBody] = useState('')
  const [whatAvoidBody, setWhatAvoidBody] = useState('')
  const [tipsBody, setTipsBody] = useState('')
  const [id, setId] = useState('')

  useEffect(() => {
    if (props.openModal && props.selectedType !== '') {
      setId(props.selectedType._id)
      Object.entries(props.selectedType).map(([key, val]) => {
        if (key === 'paragraphText') {
          setParagraphTextBody(val)
        } else if (key === 'firstParagraph') {
          setGoalBody(val)
        } else if (key === 'secondParagraph') {
          setRulesBody(val)
        } else if (key === 'thirdParagraph') {
          setWhatAvoidBody(val)
        } else if (key === 'fourthParagraph') {
          setTipsBody(val)
        } else if (key === 'personalityType' || key === 'bodyShape') {
          dispatch({ field: 'type', value: val })
        } else if (key === 'avatar') {
          setImageData(val)
        } else if (key === 'images') {
          setMultipleImages(val)
          setMultipleImageData(val)
        } else {
          dispatch({ field: key, value: val })
        }
        return null
      })
    }
    if (props.selectedTab) {
      dispatch({ field: 'type', value: props.selectedTab })
    }
  }, [props])

  function validateModalForm() {
    if (
      type !== '' &&
      tagline !== '' &&
      imageData !== '' &&
      multipleImageData.length > 0
    ) {
      if (
        props.comingFor === "Personality Type" &&
        paragraphTextBody !== ''
      ) {
        return true
      }
      if (
        props.comingFor === "Body Shape" &&
        goalBody !== '' &&
        rulesBody !== '' &&
        whatAvoidBody !== '' &&
        tipsBody !== ''
      ) {
        return true
      }
      else {
        return false
      }
    }
    else if (
      props.comingFor === "Clothing Hack" &&
      paragraphTextBody !== '' &&
      imageData !== '' &&
      multipleImageData.length > 0 &&
      question !== ''
    ) {
      return true
    }
    else if (
      props.comingFor.includes('Makeup') || props.comingFor === 'Haircut & Style' ||
      props.comingFor === 'Jewelry'
    ) {
      if (
        paragraphTextBody !== '' &&
        imageData !== '' &&
        multipleImageData.length > 0 &&
        type !== ''
      ) {
        return true
      }
    }
    else {
      return false
    }
  }

  const handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;

    setAvatar(files[0])

    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = event => {
        setImageData(event.target.result)
      };
      reader.readAsDataURL(files[0]);
    }
  }

  const handlePreviewClick = () => {
    if (!imageData) {
      return;
    }
    setFullScreen(!fullScreen)
  }

  const handleClearClick = (e) => {
    e.stopPropagation()
    setImageData('')
    setFullScreen(false)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    const inputButton = document.getElementById('categoryPic')
    inputButton.click()
  }

  const handleInputChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value })
  }

  function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
  }

  const handleMultipleFileChange = (event) => {
    var file = event.target.files;
    var joined = toArray(multipleImages).concat(toArray(file))
    setMultipleImages(joined)

    let arr = [...multipleImageData]
    for (let i = 0; i < file.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(file[i]);
      reader.onload = function (event) {
        arr.push(event.target.result)
        setMultipleImageData([...arr])
      }
    }
  };

  const onImageDelete = (index) => {
    let temp = [...multipleImageData]
    temp.splice(index, 1)
    setMultipleImageData(temp)
    let tempImages = [...multipleImages]
    tempImages.splice(index, 1)
    setMultipleImages(tempImages)
  }

  const openImageViewer = useCallback((index, val) => {
    let newImage = [];

    val.map((item, i) => {
      if (item.includes("\\")) {
        return newImage.push(uploadsUrl + item.split('\\')[1] + '/' + item.split("\\")[2]);
      }
      else if (item.includes("/")) {
        return newImage.push(uploadsUrl + item);
      }
      else {
        return newImage.push(item)
      }
    });
    setImagesDisplay(newImage)
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setImagesDisplay([])
  };

  const modalDataCleanup = () => {
    dispatch({ field: 'tagline', value: '' })
    dispatch({ field: 'type', value: '' })
    setImageData('')
    setAvatar('')
    setMultipleImageData([])
    setMultipleImages([])
    if (props.comingFor === "Personality Type" || props.comingFor === 'Clothing Hack' ||
      props.comingFor.includes('Makeup') || props.comingFor === 'Haircut & Style' ||
      props.comingFor === 'Jewelry') {
      dispatch({ field: 'question', value: '' })
      setParagraphTextBody('')
    }
    if (props.comingFor === "Body Shape") {
      setGoalBody('')
      setRulesBody('')
      setWhatAvoidBody('')
      setTipsBody('')
    }
  }

  const handleCloseModal = () => {
    modalDataCleanup()
    props.handleClose()
  }

  const handleAdd = () => {
    const data = {}
    if (props.comingFor === "Personality Type") {
      data.tagline = tagline
      data.personalityType = type
      data.paragraphText = paragraphTextBody
    } else if (props.comingFor === "Clothing Hack") {
      data.question = question
      data.paragraphText = paragraphTextBody
      data.personalityType = type
    } else if (props.comingFor === "Body Shape") {
      data.tagline = tagline
      data.bodyShape = type
      data.firstParagraph = goalBody
      data.secondParagraph = rulesBody
      data.thirdParagraph = whatAvoidBody
      data.fourthParagraph = tipsBody
    } else if (
      props.comingFor === "Face Makeup" || props.comingFor === "Nose Makeup" ||
      props.comingFor === "Eyes Makeup" || props.comingFor === "Lips Makeup" ||
      props.comingFor === "Haircut & Style" || props.comingFor === "Jewelry"
    ) {
      data.type = type
      data.paragraphText = paragraphTextBody
    }
    props.handleSubmit(data, avatar, multipleImages)
    handleCloseModal()
  }

  const handleUpdate = () => {
    const data = { id }
    if (props.comingFor === "Personality Type") {
      data.tagline = tagline
      data.personalityType = type
      data.paragraphText = paragraphTextBody
    } else if (props.comingFor === "Clothing Hack") {
      data.question = question
      data.paragraphText = paragraphTextBody
    } else if (props.comingFor === "Body Shape") {
      data.tagline = tagline
      data.bodyShape = type
      data.firstParagraph = goalBody
      data.secondParagraph = rulesBody
      data.thirdParagraph = whatAvoidBody
      data.fourthParagraph = tipsBody
    } else if (
      props.comingFor === "Face Makeup" || props.comingFor === "Nose Makeup" ||
      props.comingFor === "Eyes Makeup" || props.comingFor === "Lips Makeup" ||
      props.comingFor === "Haircut & Style" || props.comingFor === "Jewelry"
    ) {
      data.type = type
      data.paragraphText = paragraphTextBody
    }
    props.handleSubmitEdit(data, avatar, multipleImages)
    handleCloseModal()
  }

  return (
    <div>
      <Dialog
        aria-labelledby='form-dialog-title'
        open={props.openModal}
        onClose={() => handleCloseModal()}
        // disableBackdropClick
        fullWidth={true}
        maxWidth='md'
      >
        <DialogContent className='scrollBar'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-8'>
                <div className='row'>
                  <div className='col-12'>
                    <span style={styles.heading}>{props.selectedType !== '' ? 'Update ' : 'Add New '}{props.comingFor}</span><br />
                    <span style={styles.subheading}>Please attach a photo along with the required info</span>
                  </div>
                </div>
                {props.comingFor === 'Clothing Hack' ?
                  <div className='row mt-4'>
                    <div className='col-12 mt-4'>
                      <InputField
                        id={props.comingFor}
                        type='text'
                        label='Question'
                        name='question'
                        value={question}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-12 mt-4'>
                      <InputDropdown
                        id={props.comingFor}
                        type='text'
                        label='Personality Type'
                        name='type'
                        value={type}
                        onChange={handleInputChange}
                      >
                        {props.typeOptions?.map((option) => (
                          <MenuItem key={option._id} value={option.personalityType}>
                            {option.personalityType}
                          </MenuItem>
                        ))}
                      </InputDropdown>
                    </div>
                  </div>
                  : props.selectedTab ?
                    <div className='row mt-4'>
                      <div className='col-12 mt-4'>
                        <InputField
                          disabled={true}
                          id={props.comingFor}
                          type='text'
                          label={
                            props.comingFor.split(' ')[0] === 'Haircut' ?
                              'Face Shape'
                              : props.comingFor.split(' ')[0] === 'Jewelry' ?
                                'Personality Type'
                                :
                                props.comingFor.split(' ')[0] + ' Shape'
                          }
                          name={'type'}
                          value={type}
                        />
                      </div>
                    </div>
                    :
                    <div className='row mt-4'>
                      <div className='col-12 mt-4'>
                        <InputField
                          id={props.comingFor}
                          type='text'
                          label={props.comingFor}
                          name='type'
                          value={type}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='col-12 mt-4'>
                        <InputField
                          id={'Tagline'}
                          type='text'
                          label={'Tagline'}
                          name='tagline'
                          value={tagline}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                }
              </div>
              <div className='col-3'>
                <ImageInput
                  onInputClick={handleFileChange}
                  fullScreen={fullScreen}
                  imageData={imageData}
                  onPreview={handlePreviewClick}
                  onDelete={handleClearClick}
                  onEdit={handleEditClick}
                />
              </div>
            </div>
            {props.comingFor === "Body Shape" ?
              <div className="row mt-4">
                <div className='col-12 mt-4'>
                  <div style={styles.paragraphHeading}>Goal</div>
                  <TextEditor
                    setBody={setGoalBody}
                    value={goalBody}
                  />
                </div>
                <div className='col-12 mt-4'>
                  <div style={styles.paragraphHeading}>The Rules</div>
                  <TextEditor
                    setBody={setRulesBody}
                    value={rulesBody}
                  />
                </div>
                <div className='col-12 mt-4'>
                  <div style={styles.paragraphHeading}>What to Avoid</div>
                  <TextEditor
                    setBody={setWhatAvoidBody}
                    value={whatAvoidBody}
                  />
                </div>
                <div className='col-12 mt-4'>
                  <div style={styles.paragraphHeading}>Tips</div>
                  <TextEditor
                    setBody={setTipsBody}
                    value={tipsBody}
                  />
                </div>
              </div>
              :
              <div className="row mt-4">
                <div className='col-12 mt-4'>
                  <TextEditor
                    setBody={setParagraphTextBody}
                    value={paragraphTextBody}
                  />
                </div>
              </div>
            }
            <div className="row">
              <div className='col-12 mt-4'>
                {isViewerOpen && (
                  <ImageViewer
                    src={imagesDisplay}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                      backgroundColor: "rgba(0,0,0,0.9)",
                      zIndex: 99
                    }}
                  />
                )}
                <MultipleImageInput
                  onMultipleInputClick={handleMultipleFileChange}
                  multipleImageData={multipleImageData}
                  openImageViewer={openImageViewer}
                  onImageDelete={onImageDelete}
                />
              </div>
            </div>
          </div>

        </DialogContent>
        <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
          <HollowButton
            variant='medium'
            label={'Cancel'}
            onClick={() => handleCloseModal()}
          />
          {props.selectedType !== '' ?
            <Button
              disabled={!validateModalForm()}
              variant='large'
              label={`Update ${props.comingFor === "Personality Type" ? "Personality" : props.comingFor}`}
              onClick={() => handleUpdate()}
            />
            :
            <Button
              disabled={!validateModalForm()}
              variant='large'
              label={`Add ${props.comingFor === "Personality Type" ? "Personality" : props.comingFor}`}
              onClick={() => handleAdd()}
            />
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default DetailsModal
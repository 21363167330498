import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import { CustomTab, CustomTabs, useStylesForTab } from '../../styling/tabs/styleForTabs'
import Dropdown from '../../components/Dropdown/Dropdown'

export default function Wardrobe() {
    const classes = useStylesForTab();
    const [tabName, setTabName] = useState('Type 1');
    const [selection, setSelection] = useState('Personality Type');

    const handleChange = (event, newValue) => {
        setTabName(newValue);
    };

    const handleAdd = () => {
        // console.log("Add new Clicked")
    }

    const onDropdownChange = (e) => {
        setSelection(e.target.value)
    }

    return (
        <div>
            <div className='container-fluid mainPage' >
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Wardrobe</h2>
                    </div>
                    <div className='col-md-6 col-sm-5 col-5 d-flex justify-content-end pr-4'>
                        <Dropdown
                            value={selection}
                            handleDropdown={onDropdownChange}
                            options={[
                                'Personality Type',
                                'Body Proportions',
                                'Body Shape',
                                'Value Contrast'
                            ]}
                        />
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className={`col-12 mt-4 ${classes.root}`}>
                        <CustomTabs
                            centered
                            value={tabName}
                            onChange={handleChange}
                            aria-label="Custom Tab"
                        >
                            <CustomTab label="Classic" value="Type 1" />
                            <CustomTab label="Relaxed" value="Type 2" />
                            <CustomTab label="Dramatic" value="Type 3" />
                        </CustomTabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'
import './card.css'
import ClockIcon from '@material-ui/icons/AccessTimeOutlined';
import { uploadsUrl } from '../../API/endpoints'
import moment from 'moment';

export default function Card({
    index,
    item,
    handleEdit,
    handleDelete,
}) {
    return (
        <div className='cardContainer row d-flex align-items-center' key={index}>
            <div
                className='col-2 imageContainer'
                style={{
                    backgroundImage:
                        item.avatar.includes('\\') ?
                            `url("${uploadsUrl + 'Notifications/' + item.avatar.split('\\')[2]}")`
                            :
                            `url("${uploadsUrl + item.avatar}")`

                }}
            />
            <div className='col-7 pl-4'>
                <p style={{ fontWeight: 600, fontSize: '18px' }}>
                    {item.description}
                </p>
            </div>
            <div className='col-3'>
                <div className='d-flex align-items-start'>
                    <ClockIcon size='20' htmlColor='#1D70B8' />
                    <p style={{ color: 'darkgray', fontSize: '18px', marginLeft: 5 }}>
                        {moment(item.updated_at).format('MM-DD-YYYY hh:mm a')}
                    </p>
                </div>
                <div>
                    <span className='p-2'>
                        <MdDelete
                            size='26'
                            color='#FF6565'
                            cursor='pointer'
                            onClick={() => handleDelete(item)}
                        />
                    </span>
                    <span className='p-2 border-left'>
                        <MdEdit
                            size='26'
                            cursor='pointer'
                            color='#1D70B8'
                            onClick={() => handleEdit(item)}
                        />
                    </span>
                </div>
            </div>
        </div >
    )
}

import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogActions } from '@material-ui/core'
import { styles } from '../../styling/modal/modalStyle'
import ImageInput from '../ImageInput/ImageInput'
import InputField from '../InputField/InputField'
import ImageViewer from "react-simple-image-viewer";
import Button from '../Button/Button'
import HollowButton from '../HollowButton/HollowButton'

const ModalWrapper = (props) => {
    let {
        open,
        headerText,
        subHeaderText,
        labelText,
        handleClose,
        handleAdd
    } = props;

    const [imageData, setImageData] = useState('') // for preview
    const [sampleName, setSampleName] = useState('')
    const [avatar, setAvatar] = useState('')
    const [fullScreen, setFullScreen] = useState(false)
    // Image Viewer variables
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imagesDisplay, setImagesDisplay] = useState([]);

    const handleFileChange = (event) => {
        const { target } = event;
        const { files } = target;

        setAvatar(files[0])

        if (files && files[0]) {
            var reader = new FileReader();

            reader.onload = event => {
                setImageData(event.target.result)
            };
            reader.readAsDataURL(files[0]);
        }
    }

    const handlePreviewClick = () => {
        if (!imageData) {
            return;
        }
        setFullScreen(!fullScreen)
    }

    const handleClearClick = (e) => {
        e.stopPropagation()
        setImageData('')
        setFullScreen(false)
    }

    const handleEditClick = (e) => {
        e.stopPropagation()
        const inputButton = document.getElementById('categoryPic')
        inputButton.click()
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setImagesDisplay([])
    };


    const modalDataCleanup = () => {
        setAvatar('')
        setImageData('')
        setSampleName('')
    }

    const handleCloseModal = () => {
        modalDataCleanup()
        handleClose()
    }

    const handleClickAdd = () => {
        let data = {
            sampleName
        }
        handleAdd(data, avatar)
        handleCloseModal()
    }

    function validateModalForm() {
        if (
            sampleName !== '' &&
            imageData !== ''
        ) {
            return true
        }
        else return false
    }

    return (
        <Dialog
            aria-labelledby='form-dialog-title'
            open={open}
            onClose={handleCloseModal}
            // disableBackdropClick
            fullWidth={true}
            maxWidth='md'
        >
            <DialogContent className='scrollBar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span style={styles.heading}>{headerText}</span><br />
                                    <span style={styles.subheading}>{subHeaderText}</span>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-12 mt-4'>
                                    <InputField
                                        id='sampleName'
                                        type='text'
                                        label="Sample Name"
                                        name='sampleName'
                                        value={sampleName}
                                        onChange={(e) => setSampleName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <ImageInput
                                contain={true}
                                onInputClick={handleFileChange}
                                fullScreen={fullScreen}
                                imageData={imageData}
                                onPreview={handlePreviewClick}
                                onDelete={handleClearClick}
                                onEdit={handleEditClick}
                            />
                        </div>
                    </div>
                    {isViewerOpen && (
                        <ImageViewer
                            src={imagesDisplay}
                            currentIndex={currentImage}
                            onClose={closeImageViewer}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                                zIndex: 99
                            }}
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
                <HollowButton
                    variant='medium'
                    label={'Cancel'}
                    onClick={handleCloseModal}
                />
                <Button
                    disabled={!validateModalForm()}
                    variant='large'
                    label={labelText}
                    onClick={handleClickAdd}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ModalWrapper;
import React, { useState, useEffect, useCallback } from 'react'
import { BsArrowLeft } from 'react-icons/bs'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Notification from "../../components/Notification/Notification";
import { Card, CardContent, DialogActions } from '@material-ui/core'
import { Avatar, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Button from '../../components/Button/Button'
import HollowButton from '../../components/HollowButton/HollowButton'
import { styles } from './styles'
import profileImg from '../../assets/images/profile.jpg'
import DetailsList from '../../components/DetailsList/DetailsList';
import InputField from '../../components/InputField/InputField'
import { getUserProfile, submitFeedback } from './networkCalls'
import Loader from 'react-loader-spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            padding: theme.spacing(5)
        },
    },
}));

const appearanceData = {
    _id: "60a65c2a7fb464419876d2c6",
    userId: "604a742d756f793968f5d0b3",
    measurements: {
        bust: "34", waist: "34", hip: "36", shoe: "08", height: "Average"
    },
    bodyWeight: {
        body: "Mesomorph", weight: "65", unit: "kg"
    },
    bodyProportion: "Balanced Torso + Legs",
    noseShape: "Straight",
    faceShape: "Square",
    hair: {
        color: "#190A06", length: "23", unit: "cm", hairType: "Thin Fine"
    },
    lips: "Heart Shaped",
    faceColor: "Dark",
    eyes: {
        shape: "Almond", color: "#8A879A"
    }
}

const surveryData = [
    {
        _id: "60c8a8e3fbd2f21cf09e73c1",
        no: 1,
        question: "How satisfied did you feel based on your overall experience ?",
        answer: "Satisfied"
    },
    {
        _id: "60c8a8e3fbd2f21cf09e73c2",
        no: 2,
        question: "How did you learn about our App ?",
        answer: "Instagram"
    },
    {
        _id: "60c8a8e3fbd2f21cf09e73c3",
        no: 3,
        question: "On a scale of 1-10, how likely are you to recommend us to your friend or colleague ?",
        answer: "10"
    },
    {
        _id: "60c8a8e3fbd2f21cf09e73c4",
        no: 4,
        question: "What were you looking for in the app ?",
        answer: "nothing much"
    },
    {
        _id: "60c8a8e3fbd2f21cf09e73c5",
        no: 5,
        question: "Please let us know how we can improve your experience.",
        answer: "ok"
    }
]

const queriesData = [
    {
        read: false,
        _id: "60c8a937fbd2f21cf09e73c6",
        title: "Hair dressing",
        description: "i like your app very much but it needs to be more smooth in order to work smoothly specially the hair dresser part cause i cannot find it lmao.",
        time: "2021-06-15T13:20:55.482Z"
    },
    {
        read: false,
        _id: "60c8a983fbd2f21cf09e73c7",
        title: "Hair Dressing",
        description: "this is like the second time i am telling ya’ll cause there is testing going on for this app by the one guy who is developing this thingy.",
        time: "2021-06-15T13:22:11.247Z"
    }
]

export default function UserProfile(props) {
    const classes = useStyles();

    const [userData, setUserData] = useState('')
    const [selectedQuery, setSelectedQuery] = useState('')
    const [queryReply, setQueryReply] = useState('')
    const [feedbackModal, setFeedbackModal] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [personality, setPersonality] = useState('')
    const [loadedProfile, setLoadedProfile] = useState(true)
    const [, setAppearanceData] = useState(appearanceData)
    const [, setSurveryData] = useState(surveryData)
    const [, setQueriesData] = useState(queriesData)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    const getUserProfileData = useCallback((id) => {
        getUserProfile(
            id,
            setLoadedProfile,
            setPersonality,
            setAppearanceData,
            setSurveryData,
            setQueriesData,
            setOpenNotification,
            setErrorMsg,
        )
    }, [setAppearanceData, setSurveryData, setQueriesData])

    useEffect(() => {
        const userData = props.history.location.state.user
        setUserData(userData)

        getUserProfileData(userData._id)
    }, [props.history.location.state.user, getUserProfileData])


    const renderAvatar = () => (
        <span>
            <span title={`User is ${userData.status}`} style={{ ...styles.userStatusDot, backgroundColor: userData.status === "active" ? '#43D18B' : '#db4646' }} />
            <Avatar src={profileImg} style={{ width: 120, height: 120 }} />
            <span style={styles.userPersonality} title={personality + ' Personality'}>
                {loadedProfile ? personality === '' ? 'Not Selected' : personality : <Loader type='ThreeDots' color='white' height={20} width={20} />}
            </span>
        </span>
    )

    function transformText(text) {
        return text.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1).join(" ")
    }

    const renderAppearanceData = (title, data) => (
        <div key={title} style={styles.appearanceContainer}>
            <div className='row'>
                <div className='col-12'>
                    <h6 style={{ fontWeight: '600', textTransform: 'capitalize' }}>
                        {transformText(title)}
                    </h6>
                </div>
            </div>
            <div className='row'>
                {typeof data === 'object' ?
                    Object.entries(data).map(([key, val]) => (
                        <div key={key} className='col'>
                            <div className='col' style={{ color: 'gray', textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                                {transformText(key)}
                            </div>
                            <div className='col d-flex justify-content-center' style={{ fontWeight: 'bold' }}>
                                {key === 'color' ?
                                    <span style={{ ...styles.userStatusDot, margin: 0, backgroundColor: `${val}` }} />
                                    : val
                                }
                            </div>
                        </div>
                    ))
                    : <div className='col-12'>
                        {data}
                    </div>
                }
            </div>
        </div>
    )

    const handleReplyClick = (e) => {
        setSelectedQuery(e)
        setFeedbackModal(true)
    }

    const handleSubmitReply = () => {
        const params = {
            userId: userData._id,
            queryId: selectedQuery,
            reply: queryReply
        }
        submitFeedback(
            params,
            setOpenNotification,
            setsuccessMsg,
            setErrorMsg,
            handleCloseModal,
            setLoadedProfile,
            getUserProfileData
        )
    }

    const handleCloseModal = () => {
        setSelectedQuery('')
        setQueryReply('')
        setFeedbackModal(false)
    }

    const renderSkeletalCard = (count) => (
        <Card className='m-4 p-1'>
            <CardContent>
                <div className='row'>
                    <div className='col-12'>
                        <SkeletonTheme color="#e3e3e3" highlightColor="lightgray">
                            <Skeleton count={count} />
                        </SkeletonTheme>
                    </div>
                </div>
            </CardContent>
        </Card>
    )

    const renderNotFound = (d) => (
        <Card className='m-4 p-1'>
            <CardContent>
                <div className='row'>
                    <div className='col-12 d-flex justify-content-center'>
                        No {d} Found !
                    </div>
                </div>
            </CardContent>
        </Card>
    )

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <span style={styles.header}>
                            <BsArrowLeft size={50} color='gray' cursor='pointer' onClick={() => props.history.goBack()} />
                            <h2 style={{ fontWeight: '700' }}>User Profile</h2>
                        </span>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className={`col-12 mt-4 pr-4 pl-4 ${classes.root}`}>
                        <Paper elevation={3} className='col-12' style={{ backgroundColor: '#f5f5f5' }}>
                            <div className='row'>
                                <div className='col-2 d-flex justify-content-center'>
                                    {renderAvatar()}
                                </div>
                                <div className='col-10 d-flex align-content-center'>
                                    <div className='row'>
                                        <div className='col-12' style={styles.userName}>
                                            {userData.name}
                                        </div>
                                        <div className='col-12' style={{ color: 'gray' }}>
                                            {userData.email} | {userData.location}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!loadedProfile ?
                                <SkeletonTheme color="#e3e3e3" highlightColor="lightgray">
                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <Skeleton width={'40%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'28%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'16%'} style={styles.appearanceContainer} />
                                        </div>
                                        <div className='col-12'>
                                            <Skeleton width={'8%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'9%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'35%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'11%'} style={styles.appearanceContainer} />
                                            <Skeleton width={'8%'} style={styles.appearanceContainer} />
                                        </div>
                                        <div className='col-12'>
                                            <Skeleton width={'16%'} style={styles.appearanceContainer} />
                                        </div>
                                    </div>
                                </SkeletonTheme>
                                : loadedProfile && !appearanceData ?
                                    <div className='row mt-4'>
                                        <div className='col-12 d-flex align-items-center justify-content-center' style={styles.appearanceContainer}>
                                            <h5>Physical Appearance Data not filled by the user.</h5>
                                        </div>
                                    </div>
                                    : <div className='row mt-4'>
                                        {Object.entries(appearanceData).map(([key, val]) => (
                                            key !== '_id' && key !== 'userId' && key !== '__v' && renderAppearanceData(key, val)
                                        ))}
                                    </div>
                            }
                            <div className='row mt-4'>
                                <div className='col-7 mt-4'>
                                    <h5 style={{ fontWeight: '700' }}>{userData.name}'s Survey</h5>
                                    {!loadedProfile ?
                                        renderSkeletalCard(2)
                                        : loadedProfile && surveryData.length === 0 ?
                                            renderNotFound('Survey')
                                            : <DetailsList
                                                list={surveryData}
                                            />
                                    }
                                </div>
                                <div className='col-5 mt-4'>
                                    <h5 style={{ fontWeight: '700' }}>{userData.name}'s Queries</h5>
                                    {!loadedProfile ?
                                        renderSkeletalCard(4)
                                        : loadedProfile && surveryData.length === 0 ?
                                            renderNotFound('Query')
                                            : <DetailsList
                                                list={queriesData}
                                                handleClick={handleReplyClick}
                                                time={true}
                                            />
                                    }
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                <Dialog
                    aria-labelledby='form-dialog-title'
                    open={feedbackModal}
                    onClose={() => handleCloseModal()}
                    // disableBackdropClick
                    fullWidth={true}
                    maxWidth='md'
                >
                    <DialogContent className='scrollBar'>
                        <div className='row'>
                            <div className='col-12'>
                                <h4 style={{ fontWeight: 'bold' }}>Queries/Feedback Reply</h4>
                            </div>
                            <div className='col-12'>
                                <h6 style={{ color: 'gray' }}>Please reply to the user feedback for better user reach.</h6>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <InputField
                                    label='Notification Details'
                                    name={'queryReply'}
                                    value={queryReply}
                                    onChange={(e) => setQueryReply(e.target.value)}
                                    multiline={true}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
                        <HollowButton
                            variant='medium'
                            label={'Cancel'}
                            onClick={() => handleCloseModal()}
                        />
                        <Button
                            disabled={queryReply.match(/^ *$/) !== null ? true : false}
                            variant='large'
                            label={'Submit'}
                            onClick={() => handleSubmitReply()}
                        />
                    </DialogActions>
                </Dialog>
            </div>
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}


import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import Notification from "../../components/Notification/Notification";
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import {
    addNotification,
    getNotifications,
    removeNotification,
    updateNotification
} from './networkCalls'
import AddEditModal from './addEditModal';
import Loader from 'react-loader-spinner';
import { colors } from '../../constants/theme';
import Card from './card';

export default function Notifications() {

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(true)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getNotificationsData()
    }, [])

    function getNotificationsData() {
        getNotifications(
            setNotifications,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedItem(null)
    }

    const handleSubmit = (data, avatar) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        formData.append("data", JSON.stringify(data));
        addNotification(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getNotificationsData
        )
    }

    const handleSubmitEdit = (data, avatar) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        formData.append("data", JSON.stringify(data));
        updateNotification(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getNotificationsData
        )
    }

    const handleEdit = (e) => {
        setOpenDetailsModal(true)
        setSelectedItem(e)
    }

    const handleDelete = (e) => {
        setOpenConfirmation(true)
        setSelectedItem(e)
    }

    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedItem(null)
    }

    const onConfirmDelete = (e) => {
        removeNotification(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getNotificationsData,
            onCloseConfirmDelete
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Notifications</h2>
                    </div>
                    <div className='col-md-6 col-sm-5 col-5 d-flex justify-content-end pr-4'>
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div className='row mt-3 pl-2 align-items-center'>
                    {loading ?
                        <div className='col-12 mt-4 pt-4'>
                            <div className='LoaderStyle'>
                                <Loader type='Grid' color={colors.button} height={50} width={50} />
                            </div>
                        </div>
                        : notifications.length > 0 && !loading ?
                            notifications.map((item, i) => (
                                <div className='col-11 mt-4' key={i}>
                                    <Card
                                        index={i}
                                        item={item}
                                        handleDelete={handleDelete}
                                        handleEdit={handleEdit}
                                    />
                                </div>
                            ))
                            : <div className='col-12 mt-5 pt-5'>
                                <h3
                                    style={{
                                        color: colors.button,
                                        textAlign: "center"
                                    }}
                                >
                                    No Records Found for Notifications!
                                </h3>
                            </div>
                    }
                </div>
            </div>
            <AddEditModal
                open={openDetailsModal}
                headerText={`${selectedItem ? 'Edit' : 'Add New'} Notification`}
                subHeaderText='Please attach a photo along with the required info'
                labelText={`${selectedItem ? 'Edit' : 'Add'} Notification`}
                handleClose={handleClose}
                handleAdd={handleSubmit}
                handleEdit={handleSubmitEdit}
                selectedItem={selectedItem}
            />
            <ConfirmationDialog
            confirmationMsg='this notification'
                selectedType={selectedItem}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

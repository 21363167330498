import axios from "axios"
import {
    addHairURL,
    getAllHairURL,
    deleteHairURL
} from "../../API/endpoints"

export function addHairSample(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getHairSampleData
) {
    setLoading(true)
    axios
        .post(addHairURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new Hair Sample has been added successfully !");
                getHairSampleData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new sample at the moment.");
        });
}

export function getHairSamples(
    setHairData,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllHairURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setHairData(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get hair data at the moment.");
        })
}

export function removeHairSample(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getHairSampleData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteHairURL + "/" + e._id)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Sample data deleted successfully !`);
                getHairSampleData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this sample at the moment.");
        })
}
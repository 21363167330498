import React from 'react'
import { colors } from '../../constants/theme'
import { MdDelete, MdEdit } from 'react-icons/md'
import './card.css'

export default function Card({
    index,
    item,
    handleEdit,
    handleDelete,
}) {
    const createMarkup = () => {
        return { __html: item.paragraphText };
    }

    return (
        <div
            className='cardST'
            key={index}
            onMouseEnter={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style = { display: 'flex' }
                }
            }}
            onMouseLeave={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style.display = 'none'
                }
            }}
        >
            <div key={index} className="iconArea" >
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleEdit(item)}
                >
                    <MdEdit
                        size='22'
                        color='#96E7FF'
                    />
                </span>
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleDelete(item)}
                >
                    <MdDelete
                        size='22'
                        color={colors.button}
                    />
                </span>
            </div>
            <div className="cardSTContainer hidden">
                <div className="cardSTHeader row pt-2 pb-2">
                    <div className='col-2'>
                        <span
                            style={{
                                height: "45px",
                                width: "45px",
                                backgroundColor: colors.primary,
                                color: 'white',
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >{item.name}</span>
                    </div>
                    <div className='col-10 d-flex flex-column justify-content-center'>
                        <span style={{ color: colors.primary, fontWeight: 700, fontSize: "16px" }}>
                            {item.title}
                        </span>
                        <span style={{ color: 'gray', fontSize: "14px" }}>{item.tagline}</span>
                    </div>
                </div>
                <div className="cardSTBody ck-content" dangerouslySetInnerHTML={createMarkup()}>
                    {/* <p>{item.paragraphText}</p> */}
                </div>
            </div>
        </div >
    )
}

import React, { useState, useEffect } from 'react'
import Button from '../../components/Button/Button'
import DetailsModal from '../../components/DetailsModal/DetailsModal'
import { CustomTab, CustomTabs, useStylesForTab } from '../../styling/tabs/styleForTabs'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import DataTable from "../../components/DataTable/DataTable";
import Notification from "../../components/Notification/Notification";
import Dropdown from '../../components/Dropdown/Dropdown'
import { TabOptions } from './subCategoryTabs'
import { getPersonalityTypes } from '../PersonalityTypes/networkCalls'
import {
    getMakeupTips,
    addMakeupTip,
    updateMakeupTip,
    removeMakeupTip
} from './networkCalls'

export default function SubCategory(props) {
    const classes = useStylesForTab();

    const [loading, setLoading] = useState(true)
    const [tabName, setTabName] = useState('Oval');
    const [typeSelected, setTypeSelected] = useState('');
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [selectedType, setSelectedType] = useState('')
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [makeupTipsData, setMakeupTipsData] = useState([])
    const [filteredTipsData, setFilteredTipsData] = useState([])
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [personalityTypes, setPersonalityTypes] = useState([])

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        const comingFor = props.history.location.state.comingFor
        setTypeSelected(comingFor)

        getMakeupTipsData(comingFor)
    }, [props])

    function getMakeupTipsData(comingFor) {
        getMakeupTips(
            comingFor,
            setMakeupTipsData,
            setLoading,
            setOpenNotification,
            setErrorMsg,
        )
    }

    useEffect(() => {
        if (personalityTypes.length !== 0) {
            setTabName(personalityTypes[0].personalityType)
        }
    }, [personalityTypes])

    useEffect(() => {
        if (typeSelected === "Jewelry") {
            setLoading(true)
            getPersonalityTypesData()
        }
        else {
            TabOptions.forEach(val => {
                if (val.type === typeSelected) {
                    setTabName(val.options[0].value)
                }
            })
        }
        return () => {
            setTabName('')
        }
    }, [typeSelected])

    useEffect(() => {
        setFilteredTipsData(makeupTipsData.filter(d => d.type === tabName))
    }, [tabName, makeupTipsData])

    function getPersonalityTypesData() {
        getPersonalityTypes(
            setPersonalityTypes,
            setLoading,
            setOpenNotification,
            setErrorMsg,
        )
    }

    const handleChange = (event, newValue) => {
        setTabName(newValue);
    };

    const onDropdownChange = (e) => {
        setLoading(true)
        setTypeSelected(e.target.value)
        getMakeupTipsData(e.target.value)
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedType('')
    }

    const handleSubmit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages) {
            for (var x = 0; x < multipleImages.length; x++) {
                formData.append("files", multipleImages[x], multipleImages[x].name);
            }
        }
        formData.append("data", JSON.stringify(data));
        addMakeupTip(
            typeSelected,
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getMakeupTipsData
        )
    }

    const handleSubmitEdit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages && multipleImages.length > 0) {
            for (var x = 0; x < multipleImages.length; x++) {
                if (multipleImages[x].name) {
                    formData.append("newImages", multipleImages[x], multipleImages[x].name);
                }
                else {
                    formData.append("oldImages", multipleImages[x]);
                }
            }
        }
        formData.append("data", JSON.stringify(data));
        updateMakeupTip(
            typeSelected,
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getMakeupTipsData
        )
    }

    const handleEdit = (e) => {
        setOpenDetailsModal(true)
        setSelectedType(e)
    }

    const handleDelete = (e) => {
        setOpenConfirmation(true)
        setSelectedType(e)
    }

    const onCloseConfirmDelete = () => {
        setSelectedType('')
        setOpenConfirmation(false)
    }

    const onConfirmDelete = (e) => {
        removeMakeupTip(
            e,
            typeSelected,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getMakeupTipsData,
            onCloseConfirmDelete
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage' >
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>{typeSelected}</h2>
                    </div>
                    <div className='col-md-6 col-sm-5 col-5 d-flex justify-content-end pr-4'>
                        <Dropdown
                            value={typeSelected}
                            handleDropdown={onDropdownChange}
                            options={[
                                'Face Makeup',
                                'Nose Makeup',
                                'Eyes Makeup',
                                'Lips Makeup',
                                'Haircut & Style',
                                'Jewelry'
                            ]}
                        />
                        {filteredTipsData.length === 0 &&
                            <Button
                                label='Add New'
                                icon={true}
                                onClick={handleAdd}
                                variant='medium'
                            />
                        }
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className={`col-12 mt-4 ${classes.root}`}>
                        <CustomTabs
                            value={tabName}
                            onChange={handleChange}
                            aria-label="Custom Tab"
                            variant='scrollable'
                        >
                            {typeSelected === 'Jewelry' ?
                                personalityTypes.map((val, key) => {
                                    return <CustomTab key={key} label={val.personalityType} value={val.personalityType} />
                                })
                                :
                                TabOptions.map((val) =>
                                    val.type === typeSelected &&
                                    val.options.map((tab, key) => {
                                        return (
                                            <CustomTab key={key} label={tab.key} value={tab.value} />
                                        )
                                    })
                                )}
                        </CustomTabs>
                    </div>
                </div>
                <div>
                    <DataTable
                        loading={loading}
                        tableData={filteredTipsData}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        comingFor={typeSelected.split(' ').join('')}
                        type={tabName}
                    />
                </div>
            </div>
            <DetailsModal
                openModal={openDetailsModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleSubmitEdit={handleSubmitEdit}
                selectedType={selectedType}
                comingFor={typeSelected}
                selectedTab={tabName}
            />
            <ConfirmationDialog
                selectedType={selectedType}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
                comingFor={typeSelected}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

import React, { useEffect, useState, } from 'react'
import Button from '../../components/Button/Button'
import { colors } from '../../constants/theme'
import Loader from 'react-loader-spinner'
import Notification from "../../components/Notification/Notification";
import TextEditor from '../../components/TextEditor/TextEditor';
import HollowButton from '../../components/HollowButton/HollowButton';
import {
    addStyleType,
    getStyleType,
    removeStyleType,
    updateStyleType,
    addAppContentForStyleType,
    getAppContentForStyleType,
    updateAppContentForStyleType,
    removeAppContentForStyleType
} from './networkCalls'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import AddModal from './addEditModal';
import Card from './card';

export default function Index() {

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [styleTypeBody, setStyleTypeBody] = useState('')
    const [styleTypeData, setStyleTypeData] = useState([])
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingAppContent, setLoadingAppContent] = useState(true)
    const [mode, setMode] = useState('add')
    const [id, setId] = useState('')
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getAppContentData()
        getStyleTypeData()
    }, [])

    function getAppContentData() {
        getAppContentForStyleType(
            setMode,
            setId,
            setStyleTypeBody,
            setLoadingAppContent,
            setOpenNotification,
            setErrorMsg
        )
    }

    function getStyleTypeData() {
        getStyleType(
            setStyleTypeData,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
        setSelectedItem(null)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedItem(null)
    }

    const handleAddStyleType = (data) => {
        addStyleType(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getStyleTypeData
        )
    }

    const handleUpdateStyleType = (data) => {
        updateStyleType(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getStyleTypeData
        )
    }

    const handleEditItem = (e) => {
        setOpenDetailsModal(true)
        setSelectedItem(e)
    }

    const handleDeleteItem = (e) => {
        setOpenConfirmation(true)
        setSelectedItem(e)
    }


    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedItem(null)
    }

    const onConfirmDelete = (e) => {
        removeStyleType(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getStyleTypeData,
            onCloseConfirmDelete
        )
    }

    const handleSaveStyleType = () => {
        const data = {
            type: 'style_type',
            details: {
                description: styleTypeBody
            }
        }
        addAppContentForStyleType(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    const handleEditStyleType = () => {
        const data = {
            id,
            type: 'style_type',
            details: {
                description: styleTypeBody
            }
        }
        updateAppContentForStyleType(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    const handleRemoveStyleType = () => {
        removeAppContentForStyleType(
            id,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage' >
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Style Types</h2>
                    </div>
                    <div className='col-6 d-flex justify-content-end pr-4'>
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div className='row mt-5 align-items-center justify-content-center'>
                    <div className='col-8 d-flex'>
                        <p style={{ color: colors.primary, fontSize: 20, fontWeight: 500 }}>What is Style Type ?</p>
                        {loadingAppContent &&
                            <Loader type='Grid' className='ml-3' color={colors.button} height={20} width={20} />
                        }
                    </div>
                </div>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-8'>
                        <TextEditor
                            setBody={setStyleTypeBody}
                            value={styleTypeBody}
                        />
                    </div>
                    <div className='col-8 mt-3 d-flex justify-content-end'>
                        {mode === 'edit' &&
                            <span className='mr-2'>
                                <HollowButton
                                    variant='medium'
                                    label='Clear'
                                    onClick={handleRemoveStyleType}
                                />
                            </span>
                        }
                        <Button
                            disabled={styleTypeBody !== '' ? false : true}
                            variant='medium'
                            label='Save'
                            onClick={() => mode === 'add' ? handleSaveStyleType() : handleEditStyleType()}
                        />
                    </div>
                </div>
                <div className='row mt-3 pl-2 align-items-center'>
                    {loading ?
                        <div className='col-12 mt-5 d-flex justify-content-center'>
                            <Loader type='Grid' color={colors.button} height={50} width={50} />
                        </div>
                        : styleTypeData.length > 0 && !loading ?
                            styleTypeData.map((item, i) => (
                                <div className='col-4 mt-4 ml-2 mr-2' key={i}>
                                    <Card
                                        index={i}
                                        item={item}
                                        handleDelete={handleDeleteItem}
                                        handleEdit={handleEditItem}
                                    />
                                </div>
                            ))
                            : <div className='col-12 mt-5'>
                                <h3
                                    style={{
                                        color: colors.button, textAlign: "center"
                                    }}
                                >
                                    No Records Found for Style Types!
                                </h3>
                            </div>
                    }
                </div>
            </div>
            <AddModal
                open={openDetailsModal}
                headerText={selectedItem ? "Edit Style Type" : "Add Style Type"}
                labelText={selectedItem ? "Edit Style Type" : "Add Style Type"}
                handleClose={handleClose}
                handleAdd={handleAddStyleType}
                handleEdit={handleUpdateStyleType}
                selectedItem={selectedItem}
            />
            {/* {openConfirmation && */}
            <ConfirmationDialog
                selectedType={selectedItem}
                confirmationMsg={selectedItem?.name}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            {/* } */}
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div >
    )
}

import React from 'react'

export default function dashboard() {

    return (
        <div>
            {/* <div className='container-fluid mainPage' >
                <div className='row text-center'>
                    <div className='col-12'>
                        <h1>Welcome to Dashboard !</h1>
                    </div>
                    <div className='col-12'>
                        <h4>Dashboard is Under Development...</h4>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

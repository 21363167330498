import React, { useState } from 'react'
import './InputStyle.css'
import { MdDelete, MdAddCircle, MdEdit } from 'react-icons/md'
import { colors } from '../../constants/theme'
import { uploadsUrl } from '../../API/endpoints';

export default function ImageInput({
    onInputClick,
    fullScreen,
    imageData,
    onPreview,
    onDelete,
    onEdit,
    contain
}) {
    const [iconDisplay, setIconDisplay] = useState({ display: 'none' });

    return (
        <div
            onMouseEnter={e => {
                setIconDisplay({ display: 'flex' });
            }}
            onMouseLeave={e => {
                setIconDisplay({ display: 'none' })
            }}
        >
            <input
                id="categoryPic"
                className="imageInput"
                type="file"
                accept="image/*"
                onChange={onInputClick}
                onClick={(event) => {
                    event.target.value = null;
                }}
            />
            <div
                className={fullScreen ? 'preview--fullscreen' : contain ? 'preview contain' : 'preview'}
                style={imageData ? {
                    backgroundImage:
                        imageData.includes('\\') ?
                            `url('${uploadsUrl + imageData.split('\\')[1] + '/' + imageData.split('\\')[2]}')`
                            : imageData.includes('base64') ?
                                `url('${imageData}')`
                                : `url('${uploadsUrl + imageData}')`
                } :
                    null
                }
                onClick={onPreview}
            >
                {!imageData &&
                    <label htmlFor="categoryPic" className="imageLabel">
                        <MdAddCircle size='50' color='#C3C5CA' />
                        Add Image*
                    </label>
                }
                {imageData && !fullScreen &&
                    <div className='iconArea'>
                        <span className='iconSpan' style={iconDisplay} onClick={onEdit}>
                            <MdEdit
                                size='22'
                                color={'#96E7FF'}
                            />
                        </span>
                        <span className='iconSpan' style={iconDisplay} onClick={onDelete}>
                            <MdDelete
                                size='22'
                                color={colors.button}
                            />
                        </span>
                    </div>
                }
            </div>
        </div>
    )
}

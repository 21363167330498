import axios from "axios"
import {
    addNotificationURL,
    getAllNotificationsURL,
    deleteNotificationURL,
    updateNotificationURL
} from "../../API/endpoints"

export function addNotification(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getNotificationsData
) {
    setLoading(true)
    axios
        .post(addNotificationURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new notification has been added successfully !");
                getNotificationsData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new notification at the moment.");
        });
}

export function getNotifications(
    setNotifications,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllNotificationsURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setNotifications(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get notifications at the moment.");
        })
}

export function removeNotification(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getNotificationsData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteNotificationURL + "/" + e._id)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Notification deleted successfully !`);
                getNotificationsData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this notification at the moment.");
        })
}

export function updateNotification(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getNotificationsData
) {
    setLoading(true)
    axios
        .put(updateNotificationURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Notification has been updated successfully !");
                getNotificationsData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update this notification at the moment.");
        });
}
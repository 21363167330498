import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { colors } from '../../constants/theme';

export const CustomTabs = withStyles({
    root: {
        height: '60px',
    },
    indicator: {
        backgroundColor: colors.button,
        borderRadius: '50%',
        height: '12px',
        maxWidth: '12px',
        color: colors.button,
        marginLeft: '65px'
    },
})(Tabs);

export const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: '150px',
        fontWeight: '400',
        fontSize: '18px',
        fontFamily: [
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            'Roboto'
        ].join(','),
        '&:hover': {
            color: colors.button,
            opacity: 1,
        },
        '&$selected': {
            color: colors.button,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: colors.button,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStylesForTab = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: "center",
    },
    padding: {
        padding: theme.spacing(3),
    }
}));
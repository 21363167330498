import React, { useState, useReducer, useEffect, useCallback } from 'react'
import Button from '../../components/Button/Button'
import HollowButton from '../../components/HollowButton/HollowButton'
import InputField from '../../components/InputField/InputField'
import TextEditor from '../../components/TextEditor/TextEditor'
import * as yup from 'yup';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import './phoneInput.css'
import {
    addAppContentForAboutUs,
    getAppContentForAboutUs,
    updateAppContentForAboutUs,
    removeAppContentForAboutUs
} from './networkCalls'
import Loader from 'react-loader-spinner'
import Notification from '../../components/Notification/Notification'
import { colors } from '../../constants/theme'

let ABOUT_US_SCHEMA = yup.object().shape({
    location: yup.string().required(),
    email: yup.string().required().email(),
    contact: yup.number().required(),
    description: yup.string().required(),
});

export default function Aboutus() {
    const initialState = {
        email: '',
        location: '',
        contact: ''
    }

    function reducer(state, { field, value }) {
        return {
            ...state,
            [field]: value
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { email, location, contact } = state

    const [description, setDescription] = useState('')
    const [isValidated, setIsValidated] = useState(false)
    const [formErrors, setFormErrors] = useState(false)
    const [mode, setMode] = useState('add')
    const [id, setId] = useState('')
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loadingAppContent, setLoadingAppContent] = useState(true)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }


    const getAppContentData = useCallback(() => {
        getAppContentForAboutUs(
            setMode,
            setId,
            setAboutUsBody,
            setLoadingAppContent,
            setOpenNotification,
            setErrorMsg
        )
    }, [])

    useEffect(() => {
        getAppContentData()
    }, [getAppContentData])

    const setAboutUsBody = (e) => {
        if (e) {
            Object.entries(e).map(([key, val]) => {
                if (key === 'description') setDescription(val)
                return dispatch({ field: key, value: val })
            })
        }
        else {
            setDescription('')
            dispatch({ field: 'email', value: '' })
            dispatch({ field: 'location', value: '' })
            dispatch({ field: 'contact', value: '' })
        }
    }

    useEffect(() => {
        ABOUT_US_SCHEMA
            .validate({ location, email, contact, description })
            .then((valid) => {
                setIsValidated(true)
            })
            .catch((e) => {
                if (e.message.includes('email')) setFormErrors(true)
                setIsValidated(false)
            })
    }, [location, email, contact, description])

    const handleInput = (e) => {
        dispatch({ field: e.target.name, value: e.target.value })
    }

    const handleUpdate = () => {
        const data = {
            id,
            type: 'about_us',
            details: {
                location,
                email,
                contact,
                description
            }
        }
        updateAppContentForAboutUs(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    const handleAdd = () => {
        const data = {
            type: 'about_us',
            details: {
                location,
                email,
                contact,
                description
            }
        }
        addAppContentForAboutUs(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    const handleClear = () => {
        removeAppContentForAboutUs(
            id,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoadingAppContent,
            getAppContentData
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4 d-flex'>
                        <h2 style={{ fontWeight: '700' }}>About Us</h2>
                        {loadingAppContent &&
                            <Loader type='Grid' className='ml-3' color={colors.button} height={20} width={20} />
                        }
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-12 p-4'>
                        <InputField
                            id='location'
                            type='text'
                            label='Location'
                            name='location'
                            value={location}
                            onChange={handleInput}
                        />
                    </div>
                    <div className='col-12 p-4'>
                        <InputField
                            id='email'
                            type='text'
                            label='Email'
                            name='email'
                            value={email}
                            onChange={(e) => {
                                setFormErrors(false)
                                handleInput(e)
                            }}
                            error={formErrors}
                        />
                        {formErrors &&
                            <p style={{
                                color: 'red',
                                fontSize: '12px',
                                paddingLeft: '10px'
                            }}>
                                Enter a valid email address
                            </p>
                        }
                    </div>
                    <div className='col-12 p-4'>
                        <PhoneInput
                            specialLabel="Contact"
                            country='us'
                            name='contact'
                            value={contact}
                            onChange={(phone) =>
                                dispatch({ field: 'contact', value: phone })
                            }
                            inputClass='phoneInputStyle'
                            inputStyle={{
                                height: "50px",
                                width: '100%',
                                border: 'none',
                                borderBottom: '1px solid darkgray',
                                borderRadius: 0
                            }}
                        />
                    </div>
                    <div className='col-12 p-4'>
                        <TextEditor
                            setBody={setDescription}
                            value={description}
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-6 d-flex justify-content-end'>
                        {mode === 'edit' &&
                            <HollowButton
                                label='Clear'
                                onClick={handleClear}
                            />
                        }
                    </div>
                    <div className='col-6'>
                        <Button
                            disabled={!isValidated}
                            label='Update'
                            isLoading={loadingAppContent}
                            onClick={() => mode === 'add' ? handleAdd() : handleUpdate()}
                        />
                    </div>
                </div>
            </div>
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div >
    )
}

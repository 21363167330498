import axios from "axios"
import {
    addCoolUndertoneURL,
    getAllCoolUndertoneURL,
    deleteCoolUndertoneURL,
    updateCoolUndertoneURL
} from "../../API/endpoints"

export function addCoolUndertone(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getCoolUndertoneData
) {
    setLoading(true)
    axios
        .post(addCoolUndertoneURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Cool Undertone has been added successfully !");
                getCoolUndertoneData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getCoolUndertoneData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add Cool Undertone at the moment.");
            getCoolUndertoneData()
        });
}

export function getCoolUndertone(
    setMode,
    setId,
    setMultipleImages,
    setMultipleImageData,
    setCoolUndertoneData,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    axios.get(getAllCoolUndertoneURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data.length > 0) {
                    setMode('edit')
                    setCoolUndertoneData(res.data.data[0])
                    setMultipleImageData(res.data.data[0].images)
                    setMultipleImages(res.data.data[0].images)
                    setId(res.data.data[0]._id)
                }
                else{
                    setMode('add')
                    setCoolUndertoneData(null)
                    setMultipleImageData([])
                    setMultipleImages([])
                    setId('')
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get the description at the moment.");
        })
}

export function removeCoolUndertone(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getCoolUndertoneData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteCoolUndertoneURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Data has been cleared successfully !`);
                getCoolUndertoneData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getCoolUndertoneData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot clear the data at the moment.");
            getCoolUndertoneData()
        })
}

export function updateCoolUndertone(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getCoolUndertoneData
) {
    setLoading(true)
    axios
        .put(updateCoolUndertoneURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Description has been updated successfully !");
                getCoolUndertoneData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getCoolUndertoneData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update description at the moment.");
            getCoolUndertoneData()
        });
}
import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// Components
import SideMenu from '../components/SideMenu/SideMenu'
import NotFound from '../components/NotFound/NotFound'
import Header from '../components/Header/Header'
// Routes

// Screens
import Dashboard from '../views/Dashboard/dashboard'
import RegisteredUsers from '../views/RegisteredUsers/registeredusers'
import UserProfile from '../views/RegisteredUsers/userProfile'
import Glasses from '../views/Glasses'
import Hair from '../views/Hair'
import ValueContrast from '../views/ValueContrast'
import ColourPsychology from '../views/ColourPsychology'
import ColourContrast from '../views/ColourContrast'
import StyleTypes from '../views/StyleTypes'
import CoolUndertone from '../views/CoolUndertone'
import WarmUndertone from '../views/WarmUndertone'
import ColourSwatches from '../views/ColourSwatches'
import Wardrobe from '../views/Wardrobe/wardrobe'
import PersonalityTypes from '../views/PersonalityTypes/personalityTypes'
import BodyShapes from '../views/BodyShapes/bodyShapes'
import MakeupTips from '../views/MakeupTips/makeupTips'
import MakeupTipsTypes from '../views/MakeupTips/subCategory'
import TravelTips from '../views/TravelTips/travelTips'
import ClothingHacks from '../views/ClothingHacks/clothingHacks'
import Surveys from '../views/Surveys/surveys'
import AboutUs from '../views/AboutUs/aboutus'
import Notifications from '../views/Notifications/notifications'
import Settings from '../views/Settings/settings'

export default class home extends Component {
    render() {
        return (
            <>
                <Header />
                <SideMenu />
                <Switch>
                    <Route exact path={'/dashboard'} component={Dashboard} />
                    <Route exact path={'/dashboard/RegisteredUsers'} component={RegisteredUsers} />
                    <Route exact path={'/dashboard/RegisteredUsers/UserProfile'} component={UserProfile} />
                    <Route exact path={'/dashboard/glasses'} component={Glasses} />
                    <Route exact path={'/dashboard/hair'} component={Hair} />
                    <Route exact path={'/dashboard/ValueContrast'} component={ValueContrast} />
                    <Route exact path={'/dashboard/ColourPsychology'} component={ColourPsychology} />
                    <Route exact path={'/dashboard/ColourContrast'} component={ColourContrast} />
                    <Route exact path={'/dashboard/StyleTypes'} component={StyleTypes} />
                    <Route exact path={'/dashboard/CoolUndertone'} component={CoolUndertone} />
                    <Route exact path={'/dashboard/WarmUndertone'} component={WarmUndertone} />
                    <Route exact path={'/dashboard/ColourSwatches'} component={ColourSwatches} />
                    <Route exact path={'/dashboard/Wardrobe'} component={Wardrobe} />
                    <Route exact path={'/dashboard/PersonalityTypes'} component={PersonalityTypes} />
                    <Route exact path={'/dashboard/BodyShapes'} component={BodyShapes} />
                    <Route exact path={'/dashboard/MakeupTips'} component={MakeupTips} />
                    <Route exact path={'/dashboard/MakeupTips/category'} component={MakeupTipsTypes} />
                    <Route exact path={'/dashboard/TravelTips'} component={TravelTips} />
                    <Route exact path={'/dashboard/ClothingHacks'} component={ClothingHacks} />
                    <Route exact path={'/dashboard/Surveys'} component={Surveys} />
                    <Route exact path={'/dashboard/AboutUs'} component={AboutUs} />
                    <Route exact path={'/dashboard/Notifications'} component={Notifications} />
                    <Route exact path={'/dashboard/Settings'} component={Settings} />
                    <Route path='*' component={NotFound} />
                </Switch>
            </>
        )
    }
}

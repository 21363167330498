import axios from "axios"
import {
    addTravelTipsURL,
    getAllTravelTipsURL,
    deleteTravelTipsURL,
    updateTravelTipsURL
} from "../../API/endpoints"

export function addTravelTip(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getTravelTipData
) {
    setLoading(true)
    axios
        .post(addTravelTipsURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Travel Guide has been added successfully !");
                getTravelTipData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getTravelTipData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add Travel Guide at the moment.");
            getTravelTipData()
        });
}

export function getTravelTip(
    setMode,
    setId,
    setMultipleImages,
    setMultipleImageData,
    setTravelGuideBody,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    axios.get(getAllTravelTipsURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data.length > 0) {
                    // console.log("Travel Data : ", res.data.data[0])
                    setMode('edit')
                    setTravelGuideBody(res.data.data[0].paragraphText)
                    setMultipleImageData(res.data.data[0].images)
                    setMultipleImages(res.data.data[0].images)
                    setId(res.data.data[0]._id)
                }
                else{
                    setMode('add')
                    setTravelGuideBody('')
                    setMultipleImageData([])
                    setMultipleImages([])
                    setId('')
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Travel Tips at the moment.");
        })
}

export function removeTravelTip(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getTravelTipData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteTravelTipsURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Guide deleted successfully !`);
                getTravelTipData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getTravelTipData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete Travel Guide at the moment.");
            getTravelTipData()
        })
}

export function updateTravelTip(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getTravelTipData
) {
    setLoading(true)
    axios
        .put(updateTravelTipsURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Travel Tip has been updated successfully !");
                getTravelTipData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getTravelTipData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Travel Tip at the moment.");
            getTravelTipData()
        });
}
import axios from "axios"
import {
    addColourContrastURL,
    getAllColourContrastURL,
    deleteColourContrastURL,
    updateColourContrastURL
} from "../../API/endpoints"

export function addColourContrast(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourContrastData
) {
    setLoading(true)
    axios
        .post(addColourContrastURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Colour Contrast has been added successfully !");
                getColourContrastData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourContrastData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add Colour Contrast at the moment.");
            getColourContrastData()
        });
}

export function getColourContrast(
    tabName,
    setMode,
    setId,
    setMultipleImages,
    setMultipleImageData,
    setTravelGuideBody,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    setLoading(true)
    axios.get(getAllColourContrastURL)
        .then((res) => {
            if (res.data && res.data.success) {
                res.data.data = res.data.data.filter(d => d.type === tabName)
                if (res.data.data.length > 0) {
                    setMode('edit')
                    setTravelGuideBody(res.data.data[0].paragraphText)
                    setMultipleImageData(res.data.data[0].images)
                    setMultipleImages(res.data.data[0].images)
                    setId(res.data.data[0]._id)
                }
                else {
                    setMode('add')
                    setTravelGuideBody('')
                    setMultipleImageData([])
                    setMultipleImages([])
                    setId('')
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Colour Contrasts at the moment.");
        })
}

export function removeColourContrast(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourContrastData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteColourContrastURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Colour Contrast deleted successfully !`);
                getColourContrastData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourContrastData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete Colour Contrast at the moment.");
            getColourContrastData()
        })
}

export function updateColourContrast(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourContrastData
) {
    setLoading(true)
    axios
        .put(updateColourContrastURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Colour Contrast has been updated successfully !");
                getColourContrastData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourContrastData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Colour Contrast at the moment.");
            getColourContrastData()
        });
}
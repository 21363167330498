import React from 'react'
import classes from './SideBar.module.css'
import { NavLink } from 'react-router-dom'
import { BsGearFill } from 'react-icons/bs'
import {
    IoNotificationsOutline, IoInformationCircleOutline
} from 'react-icons/io5'
import { IoIosStats } from 'react-icons/io'
import { VscListUnordered } from 'react-icons/vsc'
import BStyleIcon from '../../assets/icons/BStyleIcon.png'
import BIcon from '../../assets/icons/BLight.png'
// import side bar icons
import WardrobeIcon from '../../assets/icons/SideBarIcons/Wardrobe.png'
import PersonalityTypeIcon from '../../assets/icons/SideBarIcons/Personality Type.png'
import BodyShapesIcon from '../../assets/icons/SideBarIcons/BodyShapes.png'
import MakeupTipsIcon from '../../assets/icons/SideBarIcons/MakeupTips.png'
import TravelGuideIcon from '../../assets/icons/SideBarIcons/Travel Guide.png'
import ClothingHacksIcon from '../../assets/icons/SideBarIcons/Clothing Hacks.png'
import UserIcon from '../../assets/icons/SideBarIcons/User Profile.png'
import GlassesIcon from '../../assets/icons/SideBarIcons/Glasses.png'
import HairIcon from '../../assets/icons/SideBarIcons/Hairs.png'
import ValueContrastIcon from '../../assets/icons/SideBarIcons/ValueContrast.png'
import { ReactComponent as ColourPsychologyIcon } from '../../assets/icons/SideBarIcons/ColourPsychology.svg'
import { ReactComponent as ColourContrastIcon } from '../../assets/icons/SideBarIcons/ColourContrast.svg'
import { ReactComponent as StyleTypesIcon } from '../../assets/icons/SideBarIcons/StyleTypes.svg'
import { ReactComponent as CoolUndertoneIcon } from '../../assets/icons/SideBarIcons/CoolUndertone.svg'
import { ReactComponent as WarmUndertoneIcon } from '../../assets/icons/SideBarIcons/WarmUndertone.svg'
import { ReactComponent as ColourSwatchesIcon } from '../../assets/icons/SideBarIcons/ColoursSwatches.svg'

function SideBar() {

    return (
        <aside className={`hidden ${classes.sidebar}`} style={{ backgroundImage: `url(${BIcon})` }}>
            <div className="d-flex justify-content-center">
                <img src={BStyleIcon} alt="BStyle Icon" width={150} style={{ position: 'sticky' }} />
            </div>
            <NavLink exact to="/dashboard" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li className={classes.iconClass}><IoIosStats className='ml-1 mr-3' />  Dashboard</li>
            </NavLink>
            <NavLink to="/dashboard/RegisteredUsers" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Registered Users' src={UserIcon} height={45} />  Registered Users</li>
            </NavLink>
            <NavLink to="/dashboard/glasses" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Glasses' src={GlassesIcon} height={45} />  Glasses</li>
            </NavLink>
            <NavLink to="/dashboard/hair" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Hair' src={HairIcon} height={45} />  Hair</li>
            </NavLink>
            <NavLink to="/dashboard/ValueContrast" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Value Contrast Users' src={ValueContrastIcon} height={45} />  Value Contrast</li>
            </NavLink>
            <NavLink to="/dashboard/ColourPsychology" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><ColourPsychologyIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Colour Psychology</li>
            </NavLink>
            <NavLink to="/dashboard/ColourContrast" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><ColourContrastIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Colour Contrast</li>
            </NavLink>
            <NavLink to="/dashboard/StyleTypes" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><StyleTypesIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Style Types</li>
            </NavLink>
            <NavLink to="/dashboard/CoolUndertone" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><CoolUndertoneIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Cool Undertone</li>
            </NavLink>
            <NavLink to="/dashboard/WarmUndertone" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><WarmUndertoneIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Warm Undertone</li>
            </NavLink>
            <NavLink to="/dashboard/ColourSwatches" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><ColourSwatchesIcon height={45} style={{ margin: '0rem 0.65rem' }} />  Colour Swatches</li>
            </NavLink>
            <NavLink to="/dashboard/Wardrobe" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Wardrobe' src={WardrobeIcon} height={45} />  Wardrobe</li>
            </NavLink>
            <NavLink to="/dashboard/PersonalityTypes" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Personality Types' src={PersonalityTypeIcon} height={45} />  Personality Types</li>
            </NavLink>
            <NavLink to="/dashboard/BodyShapes" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Body Shapes' src={BodyShapesIcon} width={45} />  Body Shapes</li>
            </NavLink>
            <NavLink to="/dashboard/MakeupTips" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Makeup Tips' src={MakeupTipsIcon} width={45} />  Makeup Tips</li>
            </NavLink>
            <NavLink to="/dashboard/TravelTips" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Travel Tips' src={TravelGuideIcon} width={45} />  Travel Tips</li>
            </NavLink>
            <NavLink to="/dashboard/ClothingHacks" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li><img alt='Clothing Hacks' src={ClothingHacksIcon} width={45} />  Clothing Hacks</li>
            </NavLink>
            <hr className={classes.line} />
            <NavLink to="/dashboard/Surveys" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li className={classes.iconClass}><VscListUnordered size={18} className='ml-1 mr-3' />  Surveys and Questions</li>
            </NavLink>
            <NavLink to="/dashboard/AboutUs" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li className={classes.iconClass}><IoInformationCircleOutline size={18} className='ml-1 mr-3' />  About Us</li>
            </NavLink>
            <NavLink to="/dashboard/Notifications" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li className={classes.iconClass}><IoNotificationsOutline size={18} className='ml-1 mr-3' />  Notifications</li>
            </NavLink>
            <NavLink to="/dashboard/Settings" style={{ textDecoration: 'none' }}
                activeClassName={classes.navbar__linkactive}
            >
                <li className={classes.iconClass}><BsGearFill className='ml-1 mr-3' />  Settings</li>
            </NavLink>
        </aside >
    )
}
export default SideBar
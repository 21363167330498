import React from 'react'
import faceMakeupImg from '../../assets/images/MakeupTips/Face Makeup.jpg'
import haircutImg from '../../assets/images/MakeupTips/Haircut & Style.jpg'
import eyeMakeupImg from '../../assets/images/MakeupTips/Eyes Makeup.jpg'
import lipsMakeupImg from '../../assets/images/MakeupTips/Lips Makeup.jpg'
import jewelryImg from '../../assets/images/MakeupTips/Jewelry.jpg'
import noseMakeupImg from '../../assets/images/MakeupTips/Nose Makeup.jpg'
import './makeupTipsStyle.css'

export default function makeupTips(props) {

    const makeupTipsTypes = [
        {
            name: 'Face Makeup',
            image: faceMakeupImg,
        },
        {
            name: 'Nose Makeup',
            image: noseMakeupImg,
        },
        {
            name: 'Eyes Makeup',
            image: eyeMakeupImg,
        },
        {
            name: 'Lips Makeup',
            image: lipsMakeupImg,
        },
        {
            name: 'Haircut & Style',
            image: haircutImg,
        },
        {
            name: 'Jewelry',
            image: jewelryImg,
        }
    ]

    const handleClickTip = (name) => {
        props.history.push({
            pathname: 'MakeupTips/category',
            state: { comingFor: name }
        })
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Makeup Tips</h2>
                    </div>
                </div>
                <div className='row mt-4 pl-3'>
                    {makeupTipsTypes.map((type, index) => {
                        return (
                            <div className='col mt-4' key={index}>
                                <div
                                    key={index}
                                    style={{ backgroundImage: `url("${type.image}")` }}
                                    className="typeImages"
                                    onClick={() => handleClickTip(type.name)}
                                >
                                    <span id={index} className="typeText">
                                        {type.name}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

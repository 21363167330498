import React from 'react'
import { colors } from '../../constants/theme'
import { MdDelete, MdEdit } from 'react-icons/md'
import './card.css'

export default function Card({
    index,
    item,
    handleEdit,
    handleDelete,
}) {
    return (
        <div
            className='card'
            key={index}
            onMouseEnter={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style = { display: 'flex' }
                }
            }}
            onMouseLeave={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style.display = 'none'
                }
            }}
        >
            <div key={index} className="iconArea" >
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleEdit(item)}
                >
                    <MdEdit
                        size='22'
                        color='#96E7FF'
                    />
                </span>
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleDelete(item)}
                >
                    <MdDelete
                        size='22'
                        color={colors.button}
                    />
                </span>
            </div>
            <div className="cardContainer hidden">
                <div className="cardHeader">
                    <span style={{
                        height: "35px",
                        width: "35px",
                        backgroundColor: item.colorCode,
                        borderRadius: "50%",
                        display: "inline-block"
                    }}
                    />
                    <div style={{ color: item.colorCode, fontWeight: 900, fontSize: "18px", paddingLeft: 10 }}>
                        {item.colourName}
                    </div>
                </div>
                <div className="cardBody">
                    <div style={{ fontWeight: 900 }} className='col-12'>Positive</div>
                    <div className='col-12'>{item.positiveAttrib}</div>
                    <div style={{ fontWeight: 900 }} className='col-12 mt-2'>Negatives</div>
                    <div className='col-12'>{item.negativeAttrib}</div>
                </div>
            </div>
        </div >
    )
}

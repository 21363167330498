import React, { useEffect, useState } from 'react'
import Button from '../../components/Button/Button'
import DetailsModal from '../../components/DetailsModal/DetailsModal'
import Notification from "../../components/Notification/Notification";
import DataTable from "../../components/DataTable/DataTable";
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import {
    addPersonalityType,
    getPersonalityTypes,
    removePersonalityType,
    updatePersonalityType
} from './networkCalls'

export default function PersonalityTypes() {

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [personalityTypes, setPersonalityTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedType, setSelectedType] = useState('')

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getPersonalityTypesData()
    }, [])

    function getPersonalityTypesData() {
        getPersonalityTypes(
            setPersonalityTypes,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedType('')
    }

    const handleSubmit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages) {
            for (var x = 0; x < multipleImages.length; x++) {
                formData.append("files", multipleImages[x], multipleImages[x].name);
            }
        }
        formData.append("data", JSON.stringify(data));
        addPersonalityType(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getPersonalityTypesData
        )
    }

    const handleSubmitEdit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages && multipleImages.length > 0) {
            for (var x = 0; x < multipleImages.length; x++) {
                if (multipleImages[x].name) {
                    formData.append("newImages", multipleImages[x], multipleImages[x].name);
                }
                else {
                    formData.append("oldImages", multipleImages[x]);
                }
            }
        }
        formData.append("data", JSON.stringify(data));
        updatePersonalityType(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getPersonalityTypesData
        )
    }

    const handleEdit = (e) => {
        setOpenDetailsModal(true)
        setSelectedType(e)
    }

    const handleDelete = (e) => {
        setOpenConfirmation(true)
        setSelectedType(e)
    }

    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedType('')
    }

    const onConfirmDelete = (e) => {
        removePersonalityType(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getPersonalityTypesData,
            onCloseConfirmDelete
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Personality Types</h2>
                    </div>
                    <div className='col-md-6 col-sm-5 col-5 d-flex justify-content-end pr-4'>
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div>
                    <DataTable
                        loading={loading}
                        tableData={personalityTypes}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        comingFor='PersonalityTypes'
                    />
                </div>
            </div>
            <DetailsModal
                openModal={openDetailsModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleSubmitEdit={handleSubmitEdit}
                selectedType={selectedType}
                comingFor={'Personality Type'}
            />
            <ConfirmationDialog
                selectedType={selectedType}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

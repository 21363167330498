import React, { useEffect, useState, useCallback } from 'react'
import Notification from "../../components/Notification/Notification";
import Button from '../../components/Button/Button'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import { uploadsUrl } from '../../API/endpoints';
import {
    addColorSwatch,
    getColorSwatches,
    removeColorSwatch,
    updateColorSwatch
} from './networkCalls'
import MultipleImageInput from '../../components/MultipleImageInput/MultipleImageInput';
import ColorPalatte from '../../components/ColorPalatte';
import TextEditor from '../../components/TextEditor/TextEditor';
import HollowButton from '../../components/HollowButton/HollowButton';
import { colors } from '../../constants/theme';
import InputField from '../../components/InputField/InputField'
import Card from './card';
import Loader from 'react-loader-spinner';
import ImageViewer from "react-simple-image-viewer";

let styles = {
    subHeadingStyles: {
        color: colors.primary,
        fontWeight: 500,
        fontSize: 18
    }
}

export default function Index() {

    const [addNewLayout, setAddNewLayout] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [colorSwatches, setColorSwatches] = useState([])
    const [loading, setLoading] = useState(true)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    // Image Viewer variables
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imagesDisplay, setImagesDisplay] = useState([]);
    const [multipleImageData, setMultipleImageData] = useState([]) // For Preview
    const [multipleImages, setMultipleImages] = useState([]) // For storing the images data
    // varibles for add / edit form
    const [name, setName] = useState('');
    const [tagline, setTagline] = useState('');
    const [neutrals, setNeutrals] = useState('');
    const [interests, setInterests] = useState('');
    const [metals, setMetals] = useState('');
    const [hairColours, setHairColors] = useState('');
    const [makeup, setMakeup] = useState('');
    const [colorsArr, setColorsArr] = useState([]);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getColorSwatchesData()
    }, [])

    function getColorSwatchesData() {
        getColorSwatches(
            setColorSwatches,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    function toArray(fileList) {
        return Array.prototype.slice.call(fileList);
    }

    const handleMultipleFileChange = (event) => {
        var file = event.target.files;
        var joined = toArray(multipleImages).concat(toArray(file))
        setMultipleImages(joined)

        let arr = [...multipleImageData]
        for (let i = 0; i < file.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(file[i]);
            reader.onload = function (event) {
                arr.push(event.target.result)
                setMultipleImageData([...arr])
            }
        }
    };

    const onImageDelete = (index) => {
        let temp = [...multipleImageData]
        temp.splice(index, 1)
        setMultipleImageData(temp)
        let tempImages = [...multipleImages]
        tempImages.splice(index, 1)
        setMultipleImages(tempImages)
    }

    const openImageViewer = useCallback((index, val) => {
        let newImage = [];

        val.map((item, i) => {
            if (item.includes("\\")) {
                return newImage.push(uploadsUrl + item.split('\\')[1] + '/' + item.split("\\")[2]);
            }
            else if (item.includes("/")) {
                return newImage.push(uploadsUrl + item);
            }
            else {
                return newImage.push(item)
            }
        });
        setImagesDisplay(newImage)
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setImagesDisplay([])
    };

    const handleAdd = () => {
        setAddNewLayout(true)
    }

    const handleCancel = () => {
        setAddNewLayout(false)
        setName('');
        setTagline('');
        setNeutrals('');
        setInterests('');
        setMetals('');
        setHairColors('');
        setMakeup('');
        setColorsArr([]);
        setSelectedItem(null)
        setMultipleImageData([])
        setMultipleImages([])
    }

    const handleSubmit = () => {
        let data = {
            name,
            tagline,
            neutrals,
            interests,
            metals,
            hairColours,
            makeup,
            colors: colorsArr
        }
        let formData = new FormData();

        if (multipleImages) {
            for (var x = 0; x < multipleImages.length; x++) {
                formData.append("files", multipleImages[x], multipleImages[x].name);
            }
        }
        formData.append("data", JSON.stringify(data));
        addColorSwatch(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColorSwatchesData,
            handleCancel
        )
    }

    const handleEdit = () => {
        let data = {
            id: selectedItem._id,
            name,
            tagline,
            neutrals,
            interests,
            metals,
            hairColours,
            makeup,
            colors: colorsArr
        }
        let formData = new FormData();

        if (multipleImages && multipleImages.length > 0) {
            for (var x = 0; x < multipleImages.length; x++) {
                if (multipleImages[x].name) {
                    formData.append("newImages", multipleImages[x], multipleImages[x].name);
                }
                else formData.append("oldImages", multipleImages[x]);
            }
        }
        formData.append("data", JSON.stringify(data));
        updateColorSwatch(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColorSwatchesData,
            handleCancel
        )
    }

    const handleEditClick = (e) => {
        setAddNewLayout(true)
        setSelectedItem(e)
        setName(e?.name);
        setTagline(e?.tagline);
        setNeutrals(e?.neutrals);
        setInterests(e?.interests);
        setMetals(e?.metals);
        setHairColors(e?.hairColours);
        setMakeup(e?.makeup);
        setColorsArr(e?.colors);
        setMultipleImageData(e?.images)
        setMultipleImages(e?.images)

    }

    const handleDeleteClick = (e) => {
        setOpenConfirmation(true)
        setSelectedItem(e)
    }

    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedItem(null)
    }

    const onConfirmDelete = (e) => {
        removeColorSwatch(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColorSwatchesData,
            onCloseConfirmDelete
        )
    }

    function validateForm() {
        if (
            name !== '' &&
            tagline !== '' &&
            neutrals !== '' &&
            interests !== '' &&
            metals !== '' &&
            hairColours !== '' &&
            makeup !== '' &&
            multipleImages.length > 0 &&
            colorsArr.length > 0
        ) {
            return true
        }
        else return false;
    }

    const renderAddNewLayout = () => (
        <>
            <div className='row mt-3 pl-2 align-items-center'>
                <div className='col-md-6 col-sm-5 col-5 pl-4'>
                    <h2 style={{ fontWeight: '700' }}>Add New Colour Swatch</h2>
                </div>
            </div>
            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <MultipleImageInput
                        onMultipleInputClick={handleMultipleFileChange}
                        multipleImageData={multipleImageData}
                        openImageViewer={openImageViewer}
                        onImageDelete={onImageDelete}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <h5 style={{ fontWeight: '700', marginBottom: 16 }}>Colours</h5>
                    <ColorPalatte
                        colors={colorsArr}
                        setColors={setColorsArr}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Name</p>
                    <InputField
                        id='name'
                        type='text'
                        name='name'
                        placeholder='Enter Colour Swatch Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Tagline</p>
                    <TextEditor
                        setBody={setTagline}
                        value={tagline}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Neutrals</p>
                    <TextEditor
                        setBody={setNeutrals}
                        value={neutrals}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Interests</p>
                    <TextEditor
                        setBody={setInterests}
                        value={interests}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Metals</p>
                    <TextEditor
                        setBody={setMetals}
                        value={metals}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Hair Colours</p>
                    <TextEditor
                        setBody={setHairColors}
                        value={hairColours}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4'>
                    <p style={styles.subHeadingStyles}>Dynamic Make-up</p>
                    <TextEditor
                        setBody={setMakeup}
                        value={makeup}
                    />
                </div>
            </div>

            <div className='row mt-3 pr-4'>
                <div className='col-12 pl-4 mt-3 pr-4 d-flex justify-content-end'>
                    <span className='mr-2'>
                        <HollowButton
                            variant='medium'
                            label='Cancel'
                            onClick={handleCancel}
                        />
                    </span>
                    <Button
                        disabled={!validateForm()}
                        isLoading={loading}
                        variant='medium'
                        label={selectedItem ? 'Update' : 'Submit'}
                        onClick={selectedItem ? handleEdit : handleSubmit}
                    />
                </div>
            </div>
        </>
    );

    return (
        <div>
            <div className='container-fluid mainPage' >
                {addNewLayout ? renderAddNewLayout()
                    :
                    <>
                        <div className='row mt-3 pl-2 align-items-center'>
                            <div className='col-md-6 col-sm-5 col-5 pl-4'>
                                <h2 style={{ fontWeight: '700' }}>Colour Swatches</h2>
                            </div>
                            <div className='col-md-6 col-sm-5 col-5 d-flex justify-content-end pr-4'>
                                <Button
                                    label='Add New'
                                    icon={true}
                                    onClick={handleAdd}
                                    variant='medium'
                                />
                            </div>
                        </div>
                        <div className='row mt-4 pl-2 align-items-center'>
                            {loading ?
                                <div className='col-12 mt-5 d-flex justify-content-center'>
                                    <Loader type='Grid' color={colors.button} height={50} width={50} />
                                </div>
                                : colorSwatches.length > 0 && !loading ?
                                    colorSwatches.map((item, i) => (
                                        <div className='col-3 mt-4 ml-2 mr-2' key={i}>
                                            <Card
                                                index={i}
                                                item={item}
                                                handleDelete={handleDeleteClick}
                                                handleEdit={handleEditClick}
                                            />
                                        </div>
                                    ))
                                    : <div className='col-12 mt-5'>
                                        <h3
                                            style={{
                                                color: colors.button, textAlign: "center"
                                            }}
                                        >
                                            No Records Found for Colour Swatches!
                                        </h3>
                                    </div>
                            }
                        </div>
                    </>
                }
            </div>
            <ConfirmationDialog
                confirmationMsg={selectedItem?.name ?? 'this'}
                selectedType={selectedItem}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
            {isViewerOpen && (
                <ImageViewer
                    src={imagesDisplay}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 99
                    }}
                />
            )}
        </div>
    )
}

import React from 'react'
import MaterialButton from "@material-ui/core/Button";
import { AiOutlinePlus } from 'react-icons/ai'
import { styles } from '../../styling/components/buttonStyle.js'
import { colors } from '../../constants/theme.js';

export default function HollowButton({
    label,
    disabled,
    onClick,
    icon,
    variant
}) {
    return (
        <MaterialButton
            disabled={disabled}
            style={
                disabled ? { ...styles.stylesForHollowButton, backgroundColor: colors.muted }
                    : variant === 'medium' ?
                        { ...styles.stylesForHollowButton, width: '140px', height: '45px' }
                        : styles.stylesForHollowButton
            }
            onClick={() => onClick()}
            variant="contained"
            color="default"
        >
            {icon &&
                <AiOutlinePlus fontSize='22' />
            }
            {label}
        </MaterialButton>
    )
}

import React from 'react'
import Loader from 'react-loader-spinner'
import { colors } from '../../constants/theme'
import { MdDelete, MdEdit } from 'react-icons/md'
import './dataTable.css'
import { uploadsUrl } from '../../API/endpoints'

export default function ImagesDataTable({
    comingFor,
    loading,
    tableData,
    handleEdit,
    handleDelete,
    type
}) {

    return (
        <div className="mt-4">
            {tableData.length > 0 && !loading ?
                <div className='row pl-3'>
                    {tableData.map((data, index) => {
                        return (
                            <div key={index}
                                className={comingFor === 'Clothing Hacks' ? 'col-4 mt-4' : tableData.length < 5 ? 'col-3 mt-4' : 'col mt-4'}
                            >
                                <div key={index}
                                    className={
                                        comingFor === 'Clothing Hacks' ?
                                            'questions'
                                            : comingFor === 'Glasses' || comingFor === 'Hair' ?
                                                'preview imageData contain'
                                                : 'preview imageData'}
                                    style={{
                                        backgroundImage:
                                            data.avatar.includes('\\') ?
                                                `url("${uploadsUrl + comingFor + '/' + data.avatar.split('\\')[2]}")`
                                                :
                                                `url("${uploadsUrl + data.avatar}")`

                                    }}
                                    onMouseEnter={() => {
                                        const iconShow = document.getElementsByClassName(`icon${index}`)
                                        for (var i = 0; i < iconShow.length; i++) {
                                            iconShow[i].style = { display: 'flex' }
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const iconShow = document.getElementsByClassName(`icon${index}`)
                                        for (var i = 0; i < iconShow.length; i++) {
                                            iconShow[i].style.display = 'none'
                                        }
                                    }}
                                >
                                    <div key={index}
                                        className="iconArea"
                                    >
                                        {handleEdit && <span className={comingFor === 'Clothing Hacks' ? `ch iconSpan icon${index}` : `iconSpan icon${index}`} style={{ display: 'none' }} onClick={() => handleEdit(data)}>
                                            <MdEdit
                                                size='22'
                                                color={'#96E7FF'}
                                            />
                                        </span>
                                        }
                                        <span className={comingFor === 'Clothing Hacks' ? `ch iconSpan icon${index}` : `iconSpan icon${index}`} style={{ display: 'none' }} onClick={() => handleDelete(data)}>
                                            <MdDelete
                                                size='22'
                                                color={colors.button}
                                            />
                                        </span>
                                    </div>
                                    {comingFor === 'Clothing Hacks' ?
                                        <div className="question row hidden">
                                            <span className='col-2'>{index + 1} </span>
                                            <span className='col-10'>{data.question}</span>
                                        </div>
                                        :
                                        <div className="typeText textPos">
                                            <span>{data.personalityType ? data.personalityType
                                                : data.bodyShape ? data.bodyShape
                                                    : comingFor.includes('Makeup') || comingFor === 'Haircut&Style' ?
                                                        data.type.includes('Shaped') ?
                                                            data.type
                                                            : data.type + ' Shape'
                                                        : data.type}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                : loading ?
                    <div className='LoaderStyle'>
                        <Loader type='Grid' color={colors.button} height={50} width={50} />
                    </div>
                    :
                    <div className='col-12 mt-4'>
                        <h3
                            style={{
                                color: colors.button,
                                textAlign: "center"
                            }}
                        >
                            {comingFor.includes('Makeup') ?
                                type.includes('Shaped') ?
                                    'Makeup Tips for ' + type + ' Lips'
                                    : 'Makeup Tips for ' + type + ' Shape'
                                : comingFor === 'Haircut&Style' ? 'Haircut styles for ' + type + ' Shape'
                                    : comingFor} haven't been added yet.
                        </h3>
                    </div>
            }
        </div >
    )
}

export const TabOptions = [
    {
        type: 'Face Makeup',
        options: [
            { tabValue: 0, key: 'Oval', value: 'Oval' },
            { tabValue: 1, key: 'Rectangle', value: 'Rectangle' },
            { tabValue: 2, key: 'Round', value: 'Round' },
            { tabValue: 3, key: 'Diamond', value: 'Diamond' },
            { tabValue: 4, key: 'Heart', value: 'Heart' },
            { tabValue: 5, key: 'Oblong', value: 'Oblong' },
            { tabValue: 6, key: 'Square', value: 'Square' },
            { tabValue: 8, key: 'Triangle', value: 'Triangle' }
        ]
    },
    {
        type: 'Nose Makeup',
        options: [
            { tabValue: 0, key: 'Sharp', value: 'Sharp' },
            { tabValue: 1, key: 'Round', value: 'Round' },
            { tabValue: 2, key: 'Thin', value: 'Thin' },
            { tabValue: 3, key: 'Roman', value: 'Roman' },
            { tabValue: 4, key: 'Straight', value: 'Straight' },
            { tabValue: 5, key: 'Full', value: 'Full' }
        ]
    },
    {
        type: 'Eyes Makeup',
        options: [
            { tabValue: 0, key: 'Hooded', value: 'Hooded' },
            { tabValue: 1, key: 'Monolid', value: 'Monolid' },
            { tabValue: 2, key: 'Round', value: 'Round' },
            { tabValue: 3, key: 'Upturned', value: 'Upturned' },
            { tabValue: 4, key: 'Almond', value: 'Almond' },
            { tabValue: 5, key: 'Downturned', value: 'Downturned' }
        ]
    },
    {
        type: 'Lips Makeup',
        options: [
            { tabValue: 0, key: 'Full Lips', value: 'Full Lips' },
            { tabValue: 1, key: 'Top-Heavy', value: 'Top-Heavy' },
            { tabValue: 2, key: 'Wide Lips', value: 'Wide Lips' },
            { tabValue: 3, key: 'Heart Shaped', value: 'Heart Shaped' },
            { tabValue: 4, key: 'Round', value: 'Round' },
            { tabValue: 5, key: 'Small', value: 'Small' },
            { tabValue: 5, key: 'Thin Lips', value: 'Thin Lips' }
        ]
    },
    {
        type: 'Haircut & Style',
        options: [
            { tabValue: 0, key: 'Oval', value: 'Oval' },
            { tabValue: 1, key: 'Rectangle', value: 'Rectangle' },
            { tabValue: 2, key: 'Round', value: 'Round' },
            { tabValue: 3, key: 'Diamond', value: 'Diamond' },
            { tabValue: 4, key: 'Heart', value: 'Heart' },
            { tabValue: 5, key: 'Oblong', value: 'Oblong' },
            { tabValue: 5, key: 'Square', value: 'Square' },
            { tabValue: 5, key: 'Triangle', value: 'Triangle' }
        ]
    },
]
import React, { Component } from 'react'
import { colors } from '../../constants/theme'

export default class notFound extends Component {
    render() {
        return (
            <div className="container-fluid"
                style={{
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.muted
                }}
            >
                <h1 style={{ color: 'white' }}>Error 404 !!</h1>
                <h4 style={{ color: 'white' }}>Page Not Found :(</h4>
            </div>
        )
    }
}

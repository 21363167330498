import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogActions } from '@material-ui/core'
import { styles } from '../../styling/modal/modalStyle'
import InputField from '../../components/InputField/InputField'
import Button from '../../components/Button/Button'
import HollowButton from '../../components/HollowButton/HollowButton'
import TextEditor from "../../components/TextEditor/TextEditor";
import { colors } from '../../constants/theme'

const AddModal = (props) => {
    let {
        open,
        headerText,
        subHeaderText,
        labelText,
        handleClose,
        handleAdd,
        handleEdit,
        selectedItem
    } = props;

    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [tagline, setTagline] = useState('')
    const [paragraphText, setParagraphText] = useState('')

    useEffect(() => {
        if (selectedItem) {
            setName(selectedItem?.name)
            setTitle(selectedItem?.title)
            setTagline(selectedItem?.tagline)
            setParagraphText(selectedItem?.paragraphText)
        }
        else modalDataCleanup()
    }, [selectedItem])

    const modalDataCleanup = () => {
        setName('')
        setParagraphText('')
        setTitle('')
        setTagline('')
    }

    const handleCloseModal = () => {
        modalDataCleanup()
        handleClose()
    }

    const handleClickAdd = () => {
        let data = {
            name,
            title,
            tagline,
            paragraphText
        }
        handleAdd(data)
        handleCloseModal()
    }

    const handleClickEdit = () => {
        let data = {
            id: selectedItem._id,
            name,
            title,
            tagline,
            paragraphText
        }
        handleEdit(data)
        handleCloseModal()
    }

    function validateModalForm() {
        if (
            name !== '' &&
            title !== '' &&
            tagline !== '' &&
            paragraphText !== ''
        ) {
            return true
        }
        else return false
    }

    return (
        <Dialog
            aria-labelledby='form-dialog-title'
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogContent className='scrollBar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span style={styles.heading}>{headerText}</span><br />
                                    <span style={styles.subheading}>{subHeaderText}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='name'
                                type='text'
                                label="Style Name/Abbreviation"
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='title'
                                type='text'
                                label="Title"
                                name='title'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='tagline'
                                type='text'
                                label="Tagline"
                                name='tagline'
                                value={tagline}
                                onChange={(e) => setTagline(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mt-4 pt-2 pl-4'>
                            <p style={{ color: colors.primary, fontWeight: 500 }}>Description</p>
                            <TextEditor
                                value={paragraphText}
                                setBody={setParagraphText}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
                <HollowButton
                    variant='medium'
                    label={'Cancel'}
                    onClick={handleCloseModal}
                />
                <Button
                    disabled={!validateModalForm()}
                    variant='large'
                    label={labelText}
                    onClick={selectedItem ? handleClickEdit : handleClickAdd}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddModal;
import React from 'react'
import { colors } from '../../constants/theme'
import { MdDelete, MdEdit } from 'react-icons/md'
import './card.css'

export default function Card({
    index,
    item,
    handleEdit,
    handleDelete,
}) {

    return (
        <div
            className='cardCS'
            key={index}
            onMouseEnter={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style = { display: 'flex' }
                }
            }}
            onMouseLeave={() => {
                const iconShow = document.getElementsByClassName(`icon${index}`)
                for (var i = 0; i < iconShow.length; i++) {
                    iconShow[i].style.display = 'none'
                }
            }}
        >
            <div key={index} className="iconArea" >
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleEdit(item)}
                >
                    <MdEdit
                        size='22'
                        color='#96E7FF'
                    />
                </span>
                <span
                    className={`iconSpan icon${index}`}
                    style={{ display: 'none' }}
                    onClick={() => handleDelete(item)}
                >
                    <MdDelete
                        size='22'
                        color={colors.button}
                    />
                </span>
            </div>
            <div className='palatte'>
                {item.colors.map((color) => (
                    <div style={{
                        background: color,
                        width: "100%",
                        height: `calc(100%/${item.colors.length})`
                    }}
                    />
                ))}
            </div>
            <div className="typeText textPos">
                <span>{item.name}</span>
            </div>
        </div >
    )
}

import axios from "axios"
import {
    addColourPsychologyURL,
    getAllColourPsychologysURL,
    deleteColourPsychologyURL,
    updateColourPsychologyURL
} from "../../API/endpoints"

export function addColourPsychology(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourPsychologysData
) {
    setLoading(true)
    axios
        .post(addColourPsychologyURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new colour data has been added successfully !");
                getColourPsychologysData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourPsychologysData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new colour data at the moment.");
            getColourPsychologysData()
        });
}

export function getColourPsychologys(
    setColourPsychologys,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllColourPsychologysURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setColourPsychologys(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get colour data at the moment.");
        })
}

export function removeColourPsychology(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourPsychologysData,
    onCloseConfirmDelete,
) {
    setLoading(true)
    axios.delete(deleteColourPsychologyURL + "/" + e._id)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Colour deleted successfully !`);
                getColourPsychologysData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourPsychologysData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this colour data at the moment.");
            getColourPsychologysData()
        })
}

export function updateColourPsychology(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColourPsychologysData,
) {
    setLoading(true)
    axios
        .put(updateColourPsychologyURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Colour data has been updated successfully !");
                getColourPsychologysData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getColourPsychologysData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update colour data at the moment.");
            getColourPsychologysData()
        });
}
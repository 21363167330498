import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent } from "@material-ui/core";
import Button from '../Button/Button'
import HollowButton from '../HollowButton/HollowButton'
import { SketchPicker } from 'react-color';

export default function ColorPicker({
    openPicker,
    onClickCancel,
    onClickDone
}) {

    const [color, setColor] = useState('')

    const handleCloseModal = () => {
        setColor('')
        onClickCancel()
    }

    return (
        <div>
            <Dialog
                open={openPicker}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    <h4>Pick a colour</h4>
                </DialogTitle>
                <DialogContent className="container-fluid">
                    <div className="row pb-4">
                        <div className="col-12 d-flex justify-content-center">
                            <SketchPicker
                                color={color}
                                onChangeComplete={(color) => setColor(color.hex)}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className='d-flex justify-content-center'>
                    <HollowButton
                        variant='medium'
                        label={'Cancel'}
                        onClick={handleCloseModal}
                    />
                    <Button
                        label='Done'
                        disabled={color !== '' ? false : true}
                        onClick={() => onClickDone(color)}
                        variant='large'
                    />
                </DialogActions>
            </Dialog>
        </div >
    );
}
// ----------- Use these lines when running on local
// const server = "http://localhost:3001/api";
// export const uploadsUrl = "http://localhost:3001/uploads/";

// ------- Uncomment and use the below lines on demo
const server = 'https://bstyle-backend.barrana.net/api';

export const uploadsUrl = "https://bstyle-backend.barrana.net/";

const baseUrl = server;

// Hair API URLs
export const addHairURL = `${baseUrl}/hair/addHair`;
export const getAllHairURL = `${baseUrl}/hair/getAllHair`;
export const getHairURL = `${baseUrl}/hair/getHair`;
export const deleteHairURL = `${baseUrl}/hair/deleteHair`;

// Glasses API URLs
export const addGlassesURL = `${baseUrl}/glasses/addGlasses`;
export const getAllGlassesURL = `${baseUrl}/glasses/getAllGlasses`;
export const getGlassesURL = `${baseUrl}/glasses/getGlasses`;
export const deleteGlassesURL = `${baseUrl}/glasses/deleteGlasses`;

// Colour Psychology API URLs
export const addColourPsychologyURL = `${baseUrl}/colourPsychology/addColourPsychology`;
export const updateColourPsychologyURL = `${baseUrl}/colourPsychology/updateColourPsychology`;
export const getAllColourPsychologysURL = `${baseUrl}/colourPsychology/getAllColourPsychologys`;
export const getColourPsychologysURL = `${baseUrl}/colourPsychology/getColourPsychologys`;
export const deleteColourPsychologyURL = `${baseUrl}/colourPsychology/deleteColourPsychology`;

// Style Types API URLs
export const addStyleTypeURL = `${baseUrl}/styleType/addStyleType`;
export const updateStyleTypeURL = `${baseUrl}/styleType/updateStyleType`;
export const getAllStyleTypesURL = `${baseUrl}/styleType/getAllStyleTypes`;
export const getStyleTypeURL = `${baseUrl}/styleType/getStyleType`;
export const deleteStyleTypeURL = `${baseUrl}/styleType/deleteStyleType`;

// Cool Undertone API URLs
export const addCoolUndertoneURL = `${baseUrl}/coolUndertone/addCoolUndertone`;
export const updateCoolUndertoneURL = `${baseUrl}/coolUndertone/updateCoolUndertone`;
export const getAllCoolUndertoneURL = `${baseUrl}/coolUndertone/getAllCoolUndertone`;
export const getCoolUndertoneURL = `${baseUrl}/coolUndertone/getCoolUndertone`;
export const deleteCoolUndertoneURL = `${baseUrl}/coolUndertone/deleteCoolUndertone`;

// Warm Undertone API URLs
export const addWarmUndertoneURL = `${baseUrl}/warmUndertone/addWarmUndertone`;
export const updateWarmUndertoneURL = `${baseUrl}/warmUndertone/updateWarmUndertone`;
export const getAllWarmUndertoneURL = `${baseUrl}/warmUndertone/getAllWarmUndertone`;
export const getWarmUndertoneURL = `${baseUrl}/warmUndertone/getWarmUndertone`;
export const deleteWarmUndertoneURL = `${baseUrl}/warmUndertone/deleteWarmUndertone`;

// Application Content API URLs
export const addAppContentURL = `${baseUrl}/app/addAppContent`;
export const updateAppContentURL = `${baseUrl}/app/updateAppContent`;
export const getAppContentURL = `${baseUrl}/app/getAllAppContent`;
export const deleteAppContentURL = `${baseUrl}/app/deleteAppContent`;

// Application Settings API URLs
export const updateAppSettingsURL = `${baseUrl}/appSettings/updateAppSettings`;
export const getAllAppSettingsURL = `${baseUrl}/appSettings/getAllAppSettings`;

// Colour Swatch API URLs
export const addColorSwatchURL = `${baseUrl}/colourSwatch/addColorSwatch`;
export const updateColorSwatchURL = `${baseUrl}/colourSwatch/updateColorSwatch`;
export const getAllColorSwatchesURL = `${baseUrl}/colourSwatch/getAllColorSwatches`;
export const getColorSwatchURL = `${baseUrl}/colourSwatch/getColorSwatch`;
export const deleteColorSwatchURL = `${baseUrl}/colourSwatch/deleteColorSwatch`;

// Colour Contrast API URLs
export const addColourContrastURL = `${baseUrl}/colourContrast/addColourContrast`;
export const updateColourContrastURL = `${baseUrl}/colourContrast/updateColourContrast`;
export const getAllColourContrastURL = `${baseUrl}/colourContrast/getAllColourContrast`;
export const getColourContrastURL = `${baseUrl}/colourContrast/getColourContrast`;
export const deleteColourContrastURL = `${baseUrl}/colourContrast/deleteColourContrast`;

// Value Contrast API URLs
export const addValueContrastURL = `${baseUrl}/valueContrast/addValueContrast`;
export const updateValueContrastURL = `${baseUrl}/valueContrast/updateValueContrast`;
export const getAllValueContrastURL = `${baseUrl}/valueContrast/getAllValueContrast`;
export const getValueContrastURL = `${baseUrl}/valueContrast/getValueContrast`;
export const deleteValueContrastURL = `${baseUrl}/valueContrast/deleteValueContrast`;

// Personality Type API URLs
export const addPersonalityURL = `${baseUrl}/personality/addPersonality`;
export const updatePersonalityURL = `${baseUrl}/personality/updatePersonality`;
export const getAllPersonalitiesURL = `${baseUrl}/personality/getAllPersonalities`;
export const getPersonalityURL = `${baseUrl}/personality/getPersonality`;
export const deletePersonalityURL = `${baseUrl}/personality/deletePersonality`;

// Body Shapes API URLs
export const addBodyShapeURL = `${baseUrl}/body/addBodyShape`;
export const updateBodyShapeURL = `${baseUrl}/body/updateBody`;
export const getAllBodyShapesURL = `${baseUrl}/body/getAllBodyShapes`;
export const getBodyShapeURL = `${baseUrl}/body/getBodyShape`;
export const deleteBodyURL = `${baseUrl}/body/deleteBody`;

// Clothing Hacks API URLs
export const addClothingURL = `${baseUrl}/clothing/addClothing`;
export const updateClothingURL = `${baseUrl}/clothing/updateClothing`;
export const getAllClothingsURL = `${baseUrl}/clothing/getAllClothings`;
export const getClothingsURL = `${baseUrl}/clothing/getClothings`;
export const deleteClothingURL = `${baseUrl}/clothing/deleteClothing`;

// Travel Tips API URLs
export const addTravelTipsURL = `${baseUrl}/travel/addTravelTips`;
export const updateTravelTipsURL = `${baseUrl}/travel/updateTravelTips`;
export const getAllTravelTipsURL = `${baseUrl}/travel/getAllTravelTips`;
export const getTravelTipsURL = `${baseUrl}/travel/getTravelTips`;
export const deleteTravelTipsURL = `${baseUrl}/travel/deleteTravelTips`;

//// Makeup Tips URL Points
// Face Makeup API URLs
export const addFaceMakeUpURL = `${baseUrl}/face/addFaceMakeUp`;
export const updateFaceMakeUpURL = `${baseUrl}/face/updateFaceMakeUp`;
export const getAllFaceMakeUpsURL = `${baseUrl}/face/getAllFaceMakeUps`;
export const getFaceMakeUpURL = `${baseUrl}/face/getFaceMakeUp`;
export const deleteFaceMakeUpURL = `${baseUrl}/face/deleteFaceMakeUp`;

// Nose Makeup API URLs
export const addNoseMakeUpURL = `${baseUrl}/nose/addNoseMakeUp`;
export const updateNoseMakeUpURL = `${baseUrl}/nose/updateNoseMakeUp`;
export const getAllNoseMakeUpsURL = `${baseUrl}/nose/getAllNoseMakeUps`;
export const getNoseMakeUpURL = `${baseUrl}/nose/getNoseMakeUp`;
export const deleteNoseMakeUpURL = `${baseUrl}/nose/deleteNoseMakeUp`;

// Eyes Makeup API URLs
export const addEyesMakeUpURL = `${baseUrl}/eyes/addEyesMakeUp`;
export const updateEyesMakeUpURL = `${baseUrl}/eyes/updateEyesMakeUp`;
export const getAllEyesMakeUpsURL = `${baseUrl}/eyes/getAllEyesMakeUps`;
export const getEyesMakeUpURL = `${baseUrl}/eyes/getEyesMakeUp`;
export const deleteEyesMakeUpURL = `${baseUrl}/eyes/deleteEyesMakeUp`;

// Lips Makeup API URLs
export const addLipsMakeUpURL = `${baseUrl}/lips/addLipsMakeUp`;
export const updateLipsMakeUpURL = `${baseUrl}/lips/updateLipsMakeUp`;
export const getAllLipsMakeUpsURL = `${baseUrl}/lips/getAllLipsMakeUps`;
export const getLipsMakeUpURL = `${baseUrl}/lips/getLipsMakeUp`;
export const deleteLipsMakeUpURL = `${baseUrl}/lips/deleteLipsMakeUp`;

// Haircut & Style API URLs
export const addHairCutURL = `${baseUrl}/hairCut/addHairCut`;
export const updateHairCutURL = `${baseUrl}/hairCut/updateHairCut`;
export const getAllHairCutsURL = `${baseUrl}/hairCut/getAllHairCuts`;
export const getHairCutURL = `${baseUrl}/hairCut/getHairCut`;
export const deleteHairCutURL = `${baseUrl}/hairCut/deleteHairCut`;

// Jewelry API URLs
export const addJewelryURL = `${baseUrl}/jewelry/addJewelry`;
export const updateJewelryURL = `${baseUrl}/jewelry/updateJewelry`;
export const getAllJewelriesURL = `${baseUrl}/jewelry/getAllJewelries`;
export const getJewelryURL = `${baseUrl}/jewelry/getJewelry`;
export const deleteJewelryURL = `${baseUrl}/jewelry/deleteJewelry`;

// Registered Users API URLs
export const updateUserQueryURL = `${baseUrl}/userProfile/updateQuery`;
export const getUserProfileURL = `${baseUrl}/userProfile/getUserProfile`;
export const getAllUsersURL = `${baseUrl}/userProfile/getAllUsers`;
export const getUserURL = `${baseUrl}/userProfile/getUser`;

// Notifications API URLs
export const addNotificationURL = `${baseUrl}/notifications/addNotification`;
export const updateNotificationURL = `${baseUrl}/notifications/updateNotification`;
export const getAllNotificationsURL = `${baseUrl}/notifications/getAllNotifications`;
export const getNotificationURL = `${baseUrl}/notifications/getNotification`;
export const deleteNotificationURL = `${baseUrl}/notifications/deleteNotification`;

// Quizes API URLs
export const addQuizURL = `${baseUrl}/quizes/addQuiz`;
export const updateQuizURL = `${baseUrl}/quizes/updateQuiz`;
export const getAllQuizesURL = `${baseUrl}/quizes/getAllQuizes`;
export const getQuizURL = `${baseUrl}/quizes/getQuiz`;
export const deleteQuizURL = `${baseUrl}/quizes/deleteQuiz`;
import axios from "axios"
import {
    addColorSwatchURL,
    getAllColorSwatchesURL,
    deleteColorSwatchURL,
    updateColorSwatchURL
} from "../../API/endpoints"

export function addColorSwatch(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColorSwatchesData,
    handleCancel
) {
    setLoading(true)
    axios
        .post(addColorSwatchURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new Colour Swatch has been added successfully !");
                getColorSwatchesData()
                handleCancel()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new Colour Swatch at the moment.");
        });
}

export function getColorSwatches(
    setColorSwatches,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllColorSwatchesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setColorSwatches(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Colour Swatches at the moment.");
        })
}

export function removeColorSwatch(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColorSwatchesData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteColorSwatchURL + "/" + e._id)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`${e.name} Colour Swatch deleted successfully !`);
                getColorSwatchesData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this Colour Swatch at the moment.");
        })
}

export function updateColorSwatch(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getColorSwatchesData,
    handleCancel
) {
    setLoading(true)
    axios
        .put(updateColorSwatchURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Colour Swatch has been updated successfully !");
                getColorSwatchesData()
                handleCancel()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update the data at the moment.");
        });
}
import React from "react";
import Error from "@material-ui/icons/Error";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Snackbar from "./Snackbar.js";

function Notification(props) {
  return (
    <div
      onClick={() => (props?.hideNotification() ?? {})}
    >
      {props?.msg !== "" ? (
        <Snackbar
          place="tc"
          color="danger"
          icon={Error}
          message={props.msg}
          open={props.open}
        />
      ) : props?.success !== "" && (
        <Snackbar
          place="tc"
          color="success"
          icon={CheckCircle}
          message={props.success}
          open={props.open}
        />
      )}
    </div>
  );
}
export default Notification;
import axios from "axios"
import {
    addWarmUndertoneURL,
    getAllWarmUndertoneURL,
    deleteWarmUndertoneURL,
    updateWarmUndertoneURL
} from "../../API/endpoints"

export function addWarmUndertone(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getWarmUndertoneData
) {
    setLoading(true)
    axios
        .post(addWarmUndertoneURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Warm Undertone has been added successfully !");
                getWarmUndertoneData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getWarmUndertoneData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add Warm Undertone at the moment.");
            getWarmUndertoneData()
        });
}

export function getWarmUndertone(
    setMode,
    setId,
    setMultipleImages,
    setMultipleImageData,
    setWarmUndertoneData,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    axios.get(getAllWarmUndertoneURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data.length > 0) {
                    setMode('edit')
                    setWarmUndertoneData(res.data.data[0])
                    setMultipleImageData(res.data.data[0].images)
                    setMultipleImages(res.data.data[0].images)
                    setId(res.data.data[0]._id)
                }
                else {
                    setMode('add')
                    setWarmUndertoneData(null)
                    setMultipleImageData([])
                    setMultipleImages([])
                    setId('')
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get the description at the moment.");
        })
}

export function removeWarmUndertone(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getWarmUndertoneData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteWarmUndertoneURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Data has been cleared successfully !`);
                getWarmUndertoneData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getWarmUndertoneData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot clear the data at the moment.");
            getWarmUndertoneData()
        })
}

export function updateWarmUndertone(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getWarmUndertoneData
) {
    setLoading(true)
    axios
        .put(updateWarmUndertoneURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Description has been updated successfully !");
                getWarmUndertoneData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getWarmUndertoneData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update description at the moment.");
            getWarmUndertoneData()
        });
}
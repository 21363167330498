import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmationDialog({
  selectedType,
  openConfirmation,
  onClickCancel,
  onClickDone,
  comingFor,
  confirmationMsg
}) {
  return (
    <div>
      <Dialog
        open={openConfirmation}
        onClose={() => onClickCancel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to remove {
            selectedType && !confirmationMsg ?
              typeof selectedType === 'object' ?
                selectedType?.personalityType ?
                  selectedType.personalityType
                  : selectedType?.bodyShape ?
                    selectedType.bodyShape
                    : comingFor && selectedType.type ?
                      comingFor === 'Jewelry' ?
                        'Jewelry Tips for ' + selectedType.type
                        : selectedType.type + ' Shape'
                      : 'this hack'
                : 'Travel Guides'
              : confirmationMsg && confirmationMsg
          } and its contents?
        </DialogTitle>
        <DialogActions className='d-flex justify-content-center'>
          <Button onClick={() => onClickCancel()} color="secondary" variant='contained'>
            No
          </Button>
          <Button onClick={() => onClickDone(selectedType)} color="primary" variant='contained'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
import axios from "axios"
import {
    getAllUsersURL,
    getUserURL,
    getUserProfileURL,
    updateUserQueryURL
} from "../../API/endpoints"

export function getSearchedUser(
    keyword,
    setLoading,
    setUsers,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getUserURL + '/' + keyword)
        .then((res) => {
            if (res.data && res.data.success) {
                res.data.data.map((d) => {
                    return (
                        (d.status = 'active'),
                        (d.isSubscribed = false),
                        (d.location = 'USA'),
                        (d.lastSigned = '08:00 AM')
                    )
                });
                setUsers(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get the searched user at the moment.");
        })
}

export function getRegisteredUsers(
    setLoading,
    setUsers,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllUsersURL)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log("Pesonalities : ", res.data.data)
                res.data.data.map((d) => {
                    return (
                        (d.status = 'active'),
                        (d.isSubscribed = false),
                        (d.location = 'USA'),
                        (d.lastSigned = '08:00 AM')
                    )
                });
                setUsers(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log(e)
            setOpenNotification(true);
            setErrorMsg("Cannot get Registered Users at the moment.");
        })
}

export function getUserProfile(
    userId,
    setLoadedProfile,
    setPersonality,
    setAppearanceData,
    setSurveryData,
    setQueriesData,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getUserProfileURL + '/' + userId)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log("User profile data : ", res.data.data)
                setPersonality(res.data.data?.personalityTypeId?.personalityType ?? '')
                setAppearanceData(res.data.data?.physicalAppearanceId ?? null)
                setSurveryData(res.data.data?.survey ?? [])
                setQueriesData(res.data.data?.queries ?? [])
                setLoadedProfile(true)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        }).catch((e) => {
            console.log(e)
            setOpenNotification(true);
            setErrorMsg("Cannot get User's Profile at the moment.");
        })
}

export function submitFeedback(
    params,
    setOpenNotification,
    setsuccessMsg,
    setErrorMsg,
    handleCloseModal,
    setLoadedProfile,
    getUserProfileData
) {
    setLoadedProfile(false)
    axios
        .put(updateUserQueryURL, params)
        .then((res) => {
            if (res.data && res.data.success) {
                handleCloseModal()
                setsuccessMsg("Feedback reply submitted successfully !");
                setOpenNotification(true);
                getUserProfileData(params.userId)
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                handleCloseModal()
            }
        })
        .catch((e) => {
            setErrorMsg("Cannot submit feedback reply at the moment.");
            setOpenNotification(true);
            handleCloseModal()
        });
}
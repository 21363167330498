import axios from "axios"
import {
    addPersonalityURL,
    getAllPersonalitiesURL,
    deletePersonalityURL,
    updatePersonalityURL
} from "../../API/endpoints"

export function addPersonalityType(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getPersonalityTypesData
) {
    setLoading(true)
    axios
        .post(addPersonalityURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new Personality Type has been added successfully !");
                getPersonalityTypesData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new Personality Type at the moment.");
        });
}

export function getPersonalityTypes(
    setPersonalityTypes,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllPersonalitiesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log("Pesonalities : ", res.data.data)
                setPersonalityTypes(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Personality Types at the moment.");
        })
}

export function removePersonalityType(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getPersonalityTypesData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deletePersonalityURL + "/" + e._id)
        .then((res) => {
            // console.log("response", res)
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`${e.personalityType} deleted successfully !`);
                getPersonalityTypesData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this Personality Type at the moment.");
        })
}

export function updatePersonalityType(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getPersonalityTypesData
) {
    setLoading(true)
    axios
        .put(updatePersonalityURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Personality Type has been updated successfully !");
                getPersonalityTypesData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update personality Type at the moment.");
        });
}
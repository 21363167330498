import React from 'react'
import TextField from "@material-ui/core/TextField";
import { useStylesForInput } from "../../styling/components/textfieldStyle"

export default function InputDropdown({
    id,
    type,
    label,
    name,
    value,
    onChange,
    disableUnderline,
    disabled,
    children
}) {
    const classes = useStylesForInput();

    return (
        <TextField
            select
            disabled={disabled}
            type={type}
            label={label}
            id={id}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            variant="filled"
            className={`textInputStyle ${classes.root}`}
            InputProps={{
                className: classes.input,
                disableUnderline: disableUnderline
            }}
        >
            {children}
        </TextField>
    )
}

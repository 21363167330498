import React, { useState, useEffect } from 'react'
import Button from '../../components/Button/Button'
import Notification from "../../components/Notification/Notification";
import Loader from 'react-loader-spinner'
import AddModal from './addEditModal';
import { colors } from '../../constants/theme'
import {
    addColourPsychology,
    getColourPsychologys,
    removeColourPsychology,
    updateColourPsychology
} from './networkCalls'
import Card from './card';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

export default function Index() {

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [colourPsychologyData, setColourPsychologyData] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [openConfirmation, setOpenConfirmation] = useState(false)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getColourPsychologyData()
    }, [])

    function getColourPsychologyData() {
        getColourPsychologys(
            setColourPsychologyData,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedItem(null)
    }

    const handleAddColour = (data) => {
        addColourPsychology(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColourPsychologyData
        )
    }

    const handleUpdateColour = (data) => {
        updateColourPsychology(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColourPsychologyData
        )
    }

    const handleEdit = (e) => {
        setOpenDetailsModal(true)
        setSelectedItem(e)
    }

    const handleDelete = (e) => {
        setOpenConfirmation(true)
        setSelectedItem(e)
    }


    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedItem(null)
    }

    const onConfirmDelete = (e) => {
        removeColourPsychology(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getColourPsychologyData,
            onCloseConfirmDelete
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage' >
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Colour Psychology</h2>
                    </div>
                    <div className='col-6 d-flex justify-content-end pr-4'>
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div className='row mt-3 pl-2 align-items-center'>
                    {loading ?
                        <div className='col-12 mt-4 pt-4'>
                            <div className='LoaderStyle'>
                                <Loader type='Grid' color={colors.button} height={50} width={50} />
                            </div>
                        </div>
                        : colourPsychologyData.length > 0 && !loading ?
                            colourPsychologyData.map((item, i) => (
                                <div className='col-4 mt-4' key={i}>
                                    <Card
                                        index={i}
                                        item={item}
                                        handleDelete={handleDelete}
                                        handleEdit={handleEdit}
                                    />
                                </div>
                            ))
                            : <div className='col-12 mt-5 pt-5'>
                                <h3
                                    style={{
                                        color: colors.button,
                                        textAlign: "center"
                                    }}
                                >
                                    No Records Found for Colour Psychology!
                                </h3>
                            </div>
                    }
                </div>
            </div>
            <AddModal
                open={openDetailsModal}
                headerText={selectedItem ? "Edit Colour" : "Add New Colour"}
                subHeaderText="Please select a right colour to write in"
                labelText={selectedItem ? "Edit Colour" : "Add Colour"}
                handleClose={handleClose}
                handleAdd={handleAddColour}
                handleEdit={handleUpdateColour}
                selectedItem={selectedItem}
            />
            {/* {openConfirmation && */}
            <ConfirmationDialog
                selectedType={selectedItem}
                confirmationMsg={selectedItem?.colourName}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            {/* } */}
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

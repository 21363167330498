import React, { useState, useEffect } from 'react'
import InputField from '../../components/InputField/InputField'
import { FaEye } from 'react-icons/fa'
import Notification from "../../components/Notification/Notification";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { colors } from '../../constants/theme'
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'react-loader-spinner'
import Paper from '@material-ui/core/Paper';
import { styles } from './styles'
import { getRegisteredUsers, getSearchedUser } from './networkCalls'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.action.disabledBackground,
        color: colors.primarylight,
        letterSpacing: 0.5
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

export default function Registeredusers(props) {

    const classes = useStyles();
    const [searchUser, setSearchUser] = useState("")
    const [loading, setLoading] = useState(true)
    const [timer, setTimer] = useState(null)
    const [loadSearchedData, setLoadSearchedData] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [users, setUsers] = useState([])

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getRegisteredUsersData()
    }, [])

    function getRegisteredUsersData() {
        getRegisteredUsers(
            setLoading,
            setUsers,
            setOpenNotification,
            setErrorMsg,
        )
    }

    const handleSearchUser = (e) => {
        setLoadSearchedData(true)
        clearTimeout(timer)

        const a = e.target.value
        setSearchUser(a)

        setTimer(
            setTimeout(() => {
                handleSearchUserTrigger(a)
            }, 600)
        )
    }

    const handleSearchUserTrigger = (a) => {
        if (a.length > 0) {
            getSearchedUser(
                a,
                setLoadSearchedData,
                setUsers,
                setOpenNotification,
                setErrorMsg,
            )
        } else if (a.length === 0) {
            setLoadSearchedData(false)
            getRegisteredUsersData()
        }
    }

    const handleViewUser = (e) => {
        props.history.push({
            pathname: 'RegisteredUsers/UserProfile',
            state: { user: e }
        })
    }

    const renderStatus = (val) => {
        if (val === 'active') {
            return (
                <span title='User is Active' style={{ ...styles.statusStyle, color: '#43D18B' }}><span style={{ ...styles.statusDot, backgroundColor: '#43D18B' }} />Active</span>
            )
        } else if (val === 'inactive') {
            return (
                <span title='User is Inactive' style={{ ...styles.statusStyle, color: '#db4646' }}><span style={{ ...styles.statusDot, backgroundColor: '#db4646' }} />Inactive</span>
            )
        }
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-md-6 col-sm-5 col-5 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Registered Users</h2>
                    </div>
                    <div className='col-md-6 col-sm-5 col-5 d-flex align-items-center justify-content-end pr-4'>
                        {loadSearchedData &&
                            <Loader type='Grid' color={colors.button} height={20} width={20} className='mr-2' />
                        }
                        <InputField
                            id={'Search users'}
                            type='text'
                            label={'Search users by name, email, location or status'}
                            name='Search users'
                            variant='search'
                            disableUnderline={true}
                            value={searchUser}
                            onChange={handleSearchUser}
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-12 mt-4 pr-4 pl-4'>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Subscription</StyledTableCell>
                                        <StyledTableCell>Email Address</StyledTableCell>
                                        <StyledTableCell>Last Signed in</StyledTableCell>
                                        <StyledTableCell>Location</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {users.length > 0 && !loading ?
                                    <TableBody>
                                        {users.map((user) => (
                                            <StyledTableRow key={user.name}>
                                                <StyledTableCell component="th" scope="row">
                                                    {user.name}
                                                </StyledTableCell>
                                                <StyledTableCell>{user.isSubscribed}</StyledTableCell>
                                                <StyledTableCell>{user.email}</StyledTableCell>
                                                <StyledTableCell>{user.lastSigned}</StyledTableCell>
                                                <StyledTableCell>{user.location}</StyledTableCell>
                                                <StyledTableCell>{renderStatus(user.status)}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <FaEye title='View user details' size={22} color={colors.primarylight} onClick={() => handleViewUser(user)} cursor='pointer' />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                    : loading ?
                                        <div className='LoaderStyle'>
                                            <Loader type='Grid' color={colors.button} height={50} width={50} />
                                        </div>
                                        :
                                        <h3 style={{
                                            color: colors.button, textAlign: "center", position: 'absolute', margin: 'revert',
                                            left: '35%'
                                        }}>
                                            No Registered Users Found !
                                        </h3>
                                }
                            </Table>
                        </TableContainer>

                    </div>
                </div>
            </div>
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}
import axios from "axios"
import {
    addClothingURL,
    getAllClothingsURL,
    deleteClothingURL,
    updateClothingURL
} from "../../API/endpoints"

export function addClothingHack(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getClothingHacksData,
    tabName
) {
    setLoading(true)
    axios
        .post(addClothingURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new Clothing Hack has been added successfully !");
                getClothingHacksData(tabName)
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getClothingHacksData(tabName)
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new Clothing Hack at the moment.");
            getClothingHacksData(tabName)
        });
}

export function getClothingHacks(
    tab,
    setClothingHacks,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllClothingsURL)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log("Clothing hacks : ", res.data.data)
                setClothingHacks(res.data.data.filter(d => d.personalityType === tab))
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Clothing Hack at the moment.");
        })
}

export function removeClothingHack(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getClothingHacksData,
    onCloseConfirmDelete,
    tabName
) {
    setLoading(true)
    axios.delete(deleteClothingURL + "/" + e._id)
        .then((res) => {
            // console.log("response", res)
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Hack deleted successfully !`);
                getClothingHacksData(tabName)
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getClothingHacksData(tabName)
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this Clothing Hack at the moment.");
            getClothingHacksData(tabName)
        })
}

export function updateClothingHack(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getClothingHacksData,
    tabName
) {
    setLoading(true)
    axios
        .put(updateClothingURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Clothing Hack has been updated successfully !");
                getClothingHacksData(tabName)
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getClothingHacksData(tabName)
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Clothing Hack at the moment.");
            getClothingHacksData(tabName)
        });
}
import React from 'react'
import { IoMdTime } from 'react-icons/io'
import { RiReplyFill } from 'react-icons/ri'
import { IoMdDoneAll } from 'react-icons/io'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styles } from './styles';
import { colors } from '../../constants/theme';
import formatDateTime from '../../utils/formatDateTime';

export default function DetailsList({
    list,
    handleClick,
    time
}) {
    return (
        list.map((data, index) => (
            <Card className='m-4 p-1' key={index}>
                <CardContent>
                    <div className='row'>
                        <div className='col-12' style={styles.header}>
                            {
                                !time ?
                                    data.no + '- ' + data.question
                                    :
                                    <span className='d-flex justify-content-between'>
                                        <span className='d-flex align-content-center'>
                                            <IoMdTime title='Time' color={colors.primary} size={25} style={{ marginRight: 10 }} />{formatDateTime(data.time)}
                                        </span>
                                        {!data.read ?
                                            <RiReplyFill title='Reply To Query' cursor='pointer' color={colors.primary} size={32}
                                                onClick={() => handleClick(data._id)}
                                            />
                                            :
                                            <IoMdDoneAll title='You have replied to this Query' color={colors.primary} size={30} />
                                        }
                                    </span>
                            }
                        </div>
                        <div className='col-12' style={styles.body}>
                            {data.answer}
                            {data.title}
                        </div>
                        <div className='col-12'>
                            {data.description}
                        </div>
                    </div>
                </CardContent>
            </Card>
        ))
    )
}

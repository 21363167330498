import React from "react";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
// import icon and components
import BStyleIcon from "../../assets/icons/BStyleLoginIcon.png";
import BackgroundImage from "../../assets/images/LoginBG.png";
import Notification from "../../components/Notification/Notification";
import Button from "../../components/Button/Button"
import InputField from "../../components/InputField/InputField"
import './styles.css'

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      tr: false,
      verifiedUser: false,
      errorMsg: "",
      openNotification: false,
    };
  }

  handleInput = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value });
  }

  validForm() {
    if (this.state.email !== "" && this.state.password !== "") {
      return false
    } else {
      return true
    }
  }

  handleForgetPassword() {
    this.props.history.push("/forgetpassword");
  }

  handleLogin = () => {
    if (
      this.state.email === "admin@gmail.com" &&
      this.state.password === "admin"
    ) {
      this.setState(
        {
          verifiedUser: true
        },
        () => {
          let obj = {
            name: "Admin",
            email: this.state.email,
            password: this.state.password,
          };
          cookie.save("user", obj, {
            path: "/",
          })
        })
    }
    else {
      this.setState({
        openNotification: true,
        errorMsg: "Login failed",
        tr: true,
      });
    }
  }

  render() {
    if (this.state.tr) {
      setTimeout(() => {
        this.setState({
          tr: false,
          errorMsg: "",
          openNotification: false,
        });
      }, 2000);
    }
    if (this.state.verifiedUser) {
      return <Redirect to="dashboard" />;
    }
    if (cookie.load("user")) {
      this.props.history.push("dashboard");
    }

    return (
      <div
        className="container-fluid"
        style={{
          display: "flex",
          position: "fixed",
          width: "100%",
          height: "100%"
        }}
      >
        <div className='row'
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          <div className='loginBG col-8' style={{ backgroundImage: `url(${BackgroundImage})` }} />
          
          <div className='col-4'>
            <div className="d-flex align-content-around flex-wrap"
              style={{
                height: "100%"
              }}
            >
              <div className='col-12 d-flex justify-content-center'>
                <img src={BStyleIcon} alt="BStyle Icon" width={300} />
              </div>

              <div className="col-12 loginArea">
                <InputField
                  id="email"
                  type="email"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInput}
                />
              </div>

              <div className="col-12 loginArea">
                <InputField
                  id="password"
                  type="password"
                  label="Password"
                  name={"password"}
                  value={this.state.password}
                  onChange={this.handleInput}
                />
              </div>

              <div className="col-12 loginArea">
                <Button
                  disabled={this.validForm()}
                  label='Login'
                  onClick={this.handleLogin}
                />
              </div>

              <div className="col-12 loginArea">
                <h6
                  style={{
                    cursor: "pointer",
                    color: "gray",
                    textAlign: "center"
                  }}
                  onClick={() => this.handleForgetPassword()}
                >
                  Forgot Password?
                </h6>
              </div>
            </div>
          </div>
        </div>

        <Notification
          msg={this.state.errorMsg}
          open={this.state.openNotification}
        />
      </div>
    );
  }
}

export default Login;

import { colors } from "../../constants/theme";

export const styles = {
    statusStyle: {
        fontWeight: '500',
        letterSpacing: 0.5
    },
    statusDot: {
        padding: 5,
        display: 'inline-block',
        borderRadius: '50%',
        marginRight: 5
    },
    userStatusDot: {
        padding: 10,
        borderRadius: '50%',
        position: 'absolute',
        margin: 8,
        zIndex: 2
    },
    header: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    userName: {
        fontSize: 24,
        fontWeight: '900',
        display: 'flex',
        alignItems: 'flex-end'
    },
    userPersonality: {
        position: 'absolute',
        transform: 'translate(20px, -25px)',
        backgroundColor: colors.primary,
        padding: '2px 15px',
        color: 'white',
        borderRadius: 20,
        maxWidth: 125,
        minWidth: 80,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        wordWrap: 'break-word'
    },
    appearanceContainer: {
        minHeight: 100,
        padding: 10,
        margin: 10,
        border: '1px solid darkgray',
        borderRadius: 20
    }
}
import axios from "axios"
import {
    addQuizURL,
    getAllQuizesURL,
    deleteQuizURL,
    updateQuizURL
} from "../../API/endpoints"

export function addQuiz(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getQuizData
) {
    setLoading(true)
    axios
        .post(addQuizURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Quiz link has been updated successfully !");
                getQuizData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getQuizData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add link at the moment.");
            getQuizData()
        });
}

export function getQuizes(
    setQuizData,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    axios.get(getAllQuizesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                // if (res.data.data.length > 0) {
                // setMode('edit')
                setQuizData(res.data.data)
                // setMultipleImageData(res.data.data[0].images)
                // setMultipleImages(res.data.data[0].images)
                // setId(res.data.data[0]._id)
                // }
                // else {
                // setMode('add')
                // setTravelGuideBody('')
                // setMultipleImageData([])
                // setMultipleImages([])
                // setId('')
                // }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Quizes at the moment.");
        })
}

export function removeQuiz(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getQuizData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteQuizURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Quiz deleted successfully !`);
                getQuizData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getQuizData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete the quiz at the moment.");
            getQuizData()
        })
}

export function updateQuiz(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getQuizData
) {
    setLoading(true)
    axios
        .put(updateQuizURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Quiz link has been updated successfully !");
                getQuizData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getQuizData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update link at the moment.");
            getQuizData()
        });
}
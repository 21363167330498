import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogActions } from '@material-ui/core'
import { styles } from '../../styling/modal/modalStyle'
import InputField from '../../components/InputField/InputField'
import Button from '../../components/Button/Button'
import HollowButton from '../../components/HollowButton/HollowButton'
import Circle from '@uiw/react-color-circle';
import LabelIcon from '@material-ui/icons/LocalOfferOutlined';

const AddModal = (props) => {
    let {
        open,
        headerText,
        subHeaderText,
        labelText,
        handleClose,
        handleAdd,
        handleEdit,
        selectedItem
    } = props;

    const [colourName, setcolourName] = useState('')
    const [positiveAttrib, setPositiveAttrib] = useState('')
    const [negativeAttrib, setNegativeAttrib] = useState('')
    const [colorCode, setColorCode] = useState('#B8A9FF')

    useEffect(() => {
        if (selectedItem) {
            setcolourName(selectedItem?.colourName)
            setPositiveAttrib(selectedItem?.positiveAttrib)
            setNegativeAttrib(selectedItem?.negativeAttrib)
            setColorCode(selectedItem?.colorCode)
        }
        else modalDataCleanup()
    }, [selectedItem])

    const modalDataCleanup = () => {
        setcolourName('')
        setColorCode('#B8A9FF')
        setPositiveAttrib('')
        setNegativeAttrib('')
    }

    const handleCloseModal = () => {
        modalDataCleanup()
        handleClose()
    }

    const handleClickAdd = () => {
        let data = {
            colourName,
            positiveAttrib,
            negativeAttrib,
            colorCode
        }
        handleAdd(data)
        handleCloseModal()
    }

    const handleClickEdit = () => {
        let data = {
            id: selectedItem._id,
            colourName,
            positiveAttrib,
            negativeAttrib,
            colorCode
        }
        handleEdit(data)
        handleCloseModal()
    }

    function validateModalForm() {
        if (
            colourName !== '' &&
            positiveAttrib !== '' &&
            negativeAttrib !== '' &&
            colorCode !== ''
        ) {
            return true
        }
        else return false
    }

    return (
        <Dialog
            aria-labelledby='form-dialog-title'
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogContent className='scrollBar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span style={styles.heading}>{headerText}</span><br />
                                    <span style={styles.subheading}>{subHeaderText}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='colourName'
                                type='text'
                                label="Colour Name"
                                name='colourName'
                                value={colourName}
                                onChange={(e) => setcolourName(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='positiveAttrib'
                                type='text'
                                label="Positive Attributes"
                                name='positiveAttrib'
                                value={positiveAttrib}
                                onChange={(e) => setPositiveAttrib(e.target.value)}
                            />
                        </div>
                        <div className='col-12 mt-4'>
                            <InputField
                                id='negativeAttrib'
                                type='text'
                                label="Negative Attributes"
                                name='negativeAttrib'
                                value={negativeAttrib}
                                onChange={(e) => setNegativeAttrib(e.target.value)}
                            />
                        </div>
                        <div className='col-12 d-flex mt-4 pl-3'>
                            <LabelIcon htmlColor="#9B9B9B" className="mr-3" style={{ transform: 'scale(-1, 1)' }} />
                            <Circle
                                colors={[
                                    "#B8A9FF",
                                    "#FF65C7",
                                    "#A2DB76",
                                    "#65A3FF",
                                    "#DB7676",
                                    "#BD76DB",
                                    "#5DD1A9",
                                    "#FFA967",
                                    "#1D70B8",
                                    "#F3EA71",
                                    "#A3EFEA",
                                    "#0C6098",
                                    "#FF4F7F"
                                ]}
                                color={colorCode}
                                onChange={(color) => { setColorCode(color.hex); }}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
                <HollowButton
                    variant='medium'
                    label={'Cancel'}
                    onClick={handleCloseModal}
                />
                <Button
                    disabled={!validateModalForm()}
                    variant='large'
                    label={labelText}
                    onClick={selectedItem ? handleClickEdit : handleClickAdd}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddModal;
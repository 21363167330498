import axios from "axios"
import {
    addBodyShapeURL,
    getAllBodyShapesURL,
    deleteBodyURL,
    updateBodyShapeURL
} from "../../API/endpoints"

export function addBodyShape(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getBodyShapesData
) {
    setLoading(true)
    axios
        .post(addBodyShapeURL, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new Body Shape has been added successfully !");
                getBodyShapesData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getBodyShapesData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new Body Shape at the moment.");
            getBodyShapesData()
        });
}

export function getBodyShapes(
    setBodyShapes,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllBodyShapesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log("Pesonalities : ", res.data.data)
                setBodyShapes(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Body Shape at the moment.");
        })
}

export function removeBodyShape(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getBodyShapesData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteBodyURL + "/" + e._id)
        .then((res) => {
            // console.log("response", res)
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`${e.bodyShape} deleted successfully !`);
                getBodyShapesData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getBodyShapesData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this Body Shape at the moment.");
            getBodyShapesData()
        })
}

export function updateBodyShape(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getBodyShapesData
) {
    setLoading(true)
    axios
        .put(updateBodyShapeURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Body Shape has been updated successfully !");
                getBodyShapesData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getBodyShapesData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Body Shape at the moment.");
            getBodyShapesData()
        });
}
import { colors } from "../../constants/theme";

export const styles = {
    heading: {
        fontSize: '22px',
        fontWeight: '700'
    },
    subheading: {
        fontSize: '15px',
        color: 'darkgray'
    },
    paragraphHeading: {
        color: colors.button,
        fontSize: '17px',
        fontWeight: 600,
        letterSpacing: '0.5px',
        paddingLeft: '5px',
        marginBottom: '5px'
    }
}
import React from 'react'
import './MultipleInputStyle.css'
import { MdDelete, MdAddCircle } from 'react-icons/md'
import { colors } from '../../constants/theme'
import { uploadsUrl } from '../../API/endpoints'

export default function MultipleImageInput({
    onMultipleInputClick,
    multipleImageData,
    openImageViewer,
    onImageDelete
}) {
    return (
        <div className='imageContainer' >
            {multipleImageData && multipleImageData.length > 0 && (
                <span>
                    {multipleImageData.map((view, index) => {
                        // console.log("view", view)
                        return (
                            <div key={index}
                                onMouseEnter={() => {
                                    const iconShow = document.getElementById(index)
                                    iconShow.style = { display: 'flex' }
                                }}
                                onMouseLeave={() => {
                                    const iconShow = document.getElementById(index)
                                    iconShow.style.display = 'none'
                                }}
                            >
                                <div
                                    key={index}
                                    style={{
                                        backgroundImage:
                                            view.includes('\\') ?
                                                `url('${uploadsUrl + view.split('\\')[1] + '/' + view.split('\\')[2]}')`
                                                : view.includes('base64') ?
                                                    `url('${view}')`
                                                    : `url('${uploadsUrl + view}')`
                                    }}
                                    onClick={() => openImageViewer(index, multipleImageData)}
                                    className="dressImages"
                                />
                                <span id={index} className='closeIcon' style={{ display: 'none' }} onClick={() => onImageDelete(index)}>
                                    <MdDelete
                                        size='22'
                                        color={colors.button}
                                    />
                                </span>
                            </div>
                        )
                    })}
                </span>
            )}
            <input
                multiple
                id="dressesPics"
                className="multiImageInput"
                type="file"
                accept="image/*"
                onChange={onMultipleInputClick}
                onClick={(event) => {
                    event.target.value = null;
                }}
            />
            <div className={'multipreview'}>
                <label htmlFor="dressesPics" className="multiImageLabel">
                    <MdAddCircle size='50' color='#C3C5CA' />
                    Add Images*
                </label>
            </div>
        </div >
    )
}
import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import the config here
import { config } from './editorConfig'

export default function TextEditor({
    setBody,
    value
}) {
    ClassicEditor.defaultConfig = config

    return (
        <CKEditor
            editor={ClassicEditor}
            data={value}
            onChange={(event, editor) => {
                const data = editor.getData()
                setBody(data)
            }}
            onReady={editor => {
                // Storing the "editor" and using when it is needed.
                if (value !== '') {
                    editor.setData(value);
                }
            }}
        />
    )
}

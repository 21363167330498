import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogActions } from '@material-ui/core'
import { styles } from '../../styling/modal/modalStyle'
import InputField from '../../components/InputField/InputField'
import Button from '../../components/Button/Button'
import HollowButton from '../../components/HollowButton/HollowButton'
import { useEffect } from "react";

const AddEditModal = (props) => {
    let {
        open,
        headerText,
        labelText,
        handleClose,
        handleSubmit,
        selectedItem
    } = props;

    const [link, setLink] = useState('')

    useEffect(() => {
        setLink(selectedItem?.link ?? '')
    }, [selectedItem])

    const modalDataCleanup = () => {
        setLink('')
    }

    const handleCloseModal = () => {
        modalDataCleanup()
        handleClose()
    }

    const handleClickSubmit = () => {
        let data = {
            link,
            linkFor: selectedItem?.value
        }
        handleSubmit(data)
        handleCloseModal()
    }

    function validateModalForm() {
        if (link !== '') return true
        else return false
    }

    return (
        <Dialog
            aria-labelledby='form-dialog-title'
            open={open}
            onClose={handleCloseModal}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogContent className='scrollBar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-8'>
                            <span style={styles.heading}>{headerText}</span>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 mt-4'>
                            <InputField
                                multiline={true}
                                id='link'
                                type='text'
                                label="Add Link"
                                name='link'
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='d-flex justify-content-center mt-4 mb-2'>
                <HollowButton
                    variant='medium'
                    label={'Cancel'}
                    onClick={handleCloseModal}
                />
                <Button
                    disabled={!validateModalForm()}
                    variant='large'
                    label={labelText}
                    onClick={handleClickSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddEditModal;
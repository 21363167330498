import React, { useState } from 'react'
import { MdDelete, MdAddCircle } from 'react-icons/md'
import ColorPicker from './colorPicker'
import './palatte.css'
import { colors as colorTheme } from '../../constants/theme'

export default function ColorPalatte({
    colors,
    setColors
}) {

    const [openPicker, setOpenPicker] = useState(false)

    const handleAddColor = (e) => {
        let arr = colors
        arr.push(e)
        setColors(arr)
        setOpenPicker(false)
    }

    const handleRemoveColor = (e) => {
        let arr = colors

        arr = arr.filter(item => item !== e)

        setColors(arr)
    }

    const handleOpenPicker = () => {
        setOpenPicker(true)
    }

    const handleClosePicker = () => {
        setOpenPicker(false)
    }


    return (
        <>
            {colors.map((data, index) => (
                <div
                    key={index}
                    className='box colors p-0'
                    style={{ backgroundColor: data }}
                    onMouseEnter={() => {
                        const iconShow = document.getElementsByClassName(`iconArea${index}`)
                        for (var i = 0; i < iconShow.length; i++) {
                            iconShow[i].style = { display: 'flex', position: 'absolute' }
                        }
                    }}
                    onMouseLeave={() => {
                        const iconShow = document.getElementsByClassName(`iconArea${index}`)
                        for (var i = 0; i < iconShow.length; i++) {
                            iconShow[i].style.display = 'none'
                        }
                    }}
                >
                    <div key={index}
                        className={`iconArea${index} iconBox`}
                        style={{ display: 'none' }}
                    >
                        <span
                            className='iconSpan icon'
                            onClick={() => handleRemoveColor(data)}
                        >
                            <MdDelete
                                size='22'
                                color={colorTheme.primarylight}
                            />
                        </span>
                    </div>
                    <div className='d-flex align-self-center'>
                        {data}
                    </div>
                </div>
            ))}
            <div className='box pointer' onClick={handleOpenPicker}>
                <MdAddCircle size='34' color='#C3C5CA' />
                Add Colour
            </div>

            <ColorPicker
                openPicker={openPicker}
                onClickCancel={handleClosePicker}
                onClickDone={handleAddColor}
            />
        </>
    )
}

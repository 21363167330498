import axios from "axios"
import {
    getAppContentURL,
    addAppContentURL,
    updateAppContentURL,
    deleteAppContentURL
} from "../../API/endpoints"

// Get Application Content URLs
export function addAppContentForAboutUs(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoadingAppContent,
    getAppContentData
) {
    setLoadingAppContent(true)
    axios
        .post(addAppContentURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("About Us description has been updated successfully !");
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot update the description at the moment.");
            getAppContentData()
        });
}

export function getAppContentForAboutUs(
    setMode,
    setId,
    setAboutUsBody,
    setLoadingAppContent,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAppContentURL)
        .then((res) => {
            if (res.data && res.data.success) {
                if (res.data.data?.length > 0 && res.data.data[0]?.about_us) {
                    setMode('edit')
                    setId(res.data.data[0]._id)
                    setAboutUsBody(res.data.data[0].about_us ?? null)
                } else {
                    setMode('add')
                    setAboutUsBody(null)
                }
                setLoadingAppContent(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get the description at the moment.");
        })
}

export function updateAppContentForAboutUs(
    data,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoadingAppContent,
    getAppContentData
) {
    setLoadingAppContent(true)
    axios
        .put(updateAppContentURL, data)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("About Us description has been updated successfully !");
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update the description at the moment.");
            getAppContentData()
        });
}

export function removeAppContentForAboutUs(
    id,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getAppContentData,
) {
    setLoading(true)
    axios.delete(deleteAppContentURL + "/about_us/" + id)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg(`Description cleared successfully !`);
                getAppContentData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getAppContentData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot clear the description at the moment.");
            getAppContentData()
        })
}
import React from 'react'
import MaterialButton from "@material-ui/core/Button";
import { AiOutlinePlus } from 'react-icons/ai'
import { styles } from '../../styling/components/buttonStyle.js'
import { colors } from '../../constants/theme.js';
import Loader from 'react-loader-spinner'

export default function Button({
    label,
    disabled,
    onClick,
    icon,
    variant,
    isLoading
}) {
    return (
        <MaterialButton
            disabled={disabled}
            style={
                disabled ?
                    variant === 'medium' ?
                        { ...styles.stylesForButton, backgroundColor: colors.muted, width: '140px', height: '45px' }
                        : variant === 'large' ?
                            { ...styles.stylesForButton, backgroundColor: colors.muted, width: '160px', height: '45px' }
                            : { ...styles.stylesForButton, backgroundColor: colors.muted }
                    : variant === 'medium' ?
                        { ...styles.stylesForButton, width: '140px', height: '45px' }
                        : variant === 'large' ?
                            { ...styles.stylesForButton, width: '160px', height: '45px' }
                            : styles.stylesForButton
            }
            onClick={() => isLoading ? 1 : onClick()}
            variant="contained"
            color="default"
        >
            {icon && <AiOutlinePlus fontSize='22' />}
            {isLoading ?
                <Loader type='Grid' color='white' height={18} width={18} />
                : label
            }
        </MaterialButton>
    )
}

import React, { useState, useEffect } from 'react'
import Button from '../../components/Button/Button'
import DetailsModal from '../../components/DetailsModal/DetailsModal'
import Notification from "../../components/Notification/Notification";
import DataTable from "../../components/DataTable/DataTable";
import {
    addClothingHack,
    getClothingHacks,
    removeClothingHack,
    updateClothingHack
} from './networkCalls'
import { getPersonalityTypes } from '../PersonalityTypes/networkCalls'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import { CustomTab, CustomTabs, useStylesForTab } from '../../styling/tabs/styleForTabs'

export default function ClothingHacks() {

    const classes = useStylesForTab();
    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [clothingHacks, setClothingHacks] = useState(true)
    const [personalityTypes, setPersonalityTypes] = useState([])
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [selectedHack, setSelectedHack] = useState('')
    const [tabName, setTabName] = useState(0);
    const [tabsLoading, setTabsLoading] = useState(true);

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getPersonalityTypesData()
    }, [])

    useEffect(() => {
        if (!tabsLoading) {
            let tab;
            if (personalityTypes.length > 0) {
                tab = personalityTypes[0].personalityType
                setTabName(tab)
            }
            getClothingHacksData(tab)
        }
    }, [tabsLoading, personalityTypes])

    function getPersonalityTypesData() {
        getPersonalityTypes(
            setPersonalityTypes,
            setTabsLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    function getClothingHacksData(tab) {
        getClothingHacks(
            tab,
            setClothingHacks,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleAdd = () => {
        setOpenDetailsModal(true)
    }

    const handleEdit = (e) => {
        setOpenDetailsModal(true)
        setSelectedHack(e)
    }

    const handleDelete = (e) => {
        setOpenConfirmation(true)
        setSelectedHack(e)
    }

    const handleClose = () => {
        setOpenDetailsModal(false)
        setSelectedHack('')
    }

    const handleSubmit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages) {
            for (var x = 0; x < multipleImages.length; x++) {
                formData.append("files", multipleImages[x], multipleImages[x].name);
            }
        }
        formData.append("data", JSON.stringify(data));
        addClothingHack(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getClothingHacksData,
            tabName
        )
    }

    const handleSubmitEdit = (data, avatar, multipleImages) => {
        let formData = new FormData();
        if (avatar) {
            formData.append("avatar", avatar, avatar.name);
        }
        if (multipleImages && multipleImages.length > 0) {
            for (var x = 0; x < multipleImages.length; x++) {
                if (multipleImages[x].name) {
                    formData.append("newImages", multipleImages[x], multipleImages[x].name);
                }
                else {
                    formData.append("oldImages", multipleImages[x]);
                }
            }
        }
        formData.append("data", JSON.stringify(data));
        updateClothingHack(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getClothingHacksData,
            tabName
        )
    }

    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setSelectedHack('')
    }

    const onConfirmDelete = (e) => {
        removeClothingHack(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getClothingHacksData,
            onCloseConfirmDelete,
            tabName
        )
    }

    const handleTabChange = (event, newValue) => {
        setTabName(newValue);
        setLoading(true)
        getClothingHacksData(newValue)
    };


    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2 align-items-center'>
                    <div className='col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Clothing Hacks</h2>
                    </div>
                    <div className='col-6 d-flex justify-content-end pr-4'>
                        <Button
                            label='Add New'
                            icon={true}
                            onClick={handleAdd}
                            variant='medium'
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className={`col-12 mt-4 ${classes.root}`}>
                        <CustomTabs
                            centered
                            value={tabName}
                            onChange={handleTabChange}
                            aria-label="Custom Tab"
                        >
                            {personalityTypes.map((d, i) =>
                                <CustomTab label={d.personalityType} value={d.personalityType} />
                            )}
                        </CustomTabs>
                    </div>
                </div>
                <div>
                    <DataTable
                        loading={loading}
                        tableData={clothingHacks}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        comingFor={'Clothing Hacks'}
                    />
                </div>
            </div>
            <DetailsModal
                openModal={openDetailsModal}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                handleSubmitEdit={handleSubmitEdit}
                selectedType={selectedHack}
                selectedTab={tabName}
                comingFor={'Clothing Hack'}
                typeOptions={personalityTypes}
            />
            <ConfirmationDialog
                selectedType={selectedHack}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

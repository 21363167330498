import React, { useState } from 'react'
import classes from './TopBar.module.css'
import { IoLogOutOutline } from 'react-icons/io5'
import { IoIosArrowDown } from 'react-icons/io'
import { Dropdown } from 'react-bootstrap'
import cookie from 'react-cookies'
import ProfilePic from '../../assets/images/profile.jpg'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    className={classes.Toggle}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IoIosArrowDown color='gray' size='18' />
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

function TopBar(props) {
  const role = cookie.load('user');

  function logout() {
    cookie.remove("user", { path: "/" });
    window.location.reload();
  }

  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        <div className={classes.space}>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} >
              <span className='mr-2'>{role.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu align='right' as={CustomMenu}>
              <Dropdown.Item
                eventKey="1"
                onClick={() => logout()}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
              >
                <IoLogOutOutline color='black' size='18' />Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={classes.space}>
          <div
            className={classes.profileImage}
            style={{
              backgroundImage: `url('${ProfilePic}')`
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default TopBar
import { ButtonBase, IconButton, InputAdornment, Switch } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import Loader from 'react-loader-spinner';
import TextInput from '../../components/InputField/InputField';
import { colors } from '../../constants/theme';
import { ReactComponent as LockIcon } from "../../assets/icons/svg/lockIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/svg/logout.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/svg/mail.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/svg/notificationIcon.svg";
import Button from '../../components/Button/Button';
import {
    updateAppSettings,
    getaAppSettings
} from './networkCalls'
import Notification from '../../components/Notification/Notification';
import { useCallback } from 'react';

export default function Settings() {

    const [id, setId] = useState(null)
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false)
    const [notificationEnabled, setNotificationEnabled] = useState(false)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    const getaAppSettingsData = useCallback(() => {
        getaAppSettings(
            setId,
            setAppSettingsBody,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }, [])

    useEffect(() => {
        getaAppSettingsData()
    }, [getaAppSettingsData])

    const setAppSettingsBody = (e) => {
        setEmail(e?.email ?? '')
        setPassword(e?.password ?? '')
        setNotificationEnabled(e?.notifications ?? false)
    }

    const validateForm = () => {
        if (email !== '' && password !== '')
            return true
        else return false
    }

    const handleSave = () => {
        const data = {
            email,
            password
        }
        if (id) data.id = id
        updateAppSettings(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getaAppSettingsData
        )
    }

    const handleNotifySave = (e) => {
        const data = {
            notifications: e.target.checked
        }
        if (id) data.id = id
        updateAppSettings(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getaAppSettingsData
        )
    }

    const handleLogout = () => {

    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='d-flex col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Settings</h2>
                        <span className='d-flex pl-4 pt-1'>
                            {loading && <Loader type='Grid' color={colors.button} height={25} width={25} />}
                        </span>
                    </div>
                </div>
                <div className='row mt-4 pl-2 mb-5'>
                    <div className='d-flex col-12 pl-4'>
                        <h4 style={{ color: '#60606A' }}>Account</h4>
                    </div>
                    <div className='d-flex col-4 pl-4'>
                        <TextInput
                            id='email'
                            type='email'
                            label='Email'
                            name='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start" tabIndex={0}>
                                    <MailIcon width={22} height={22} />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className='d-flex col-4 pl-4'>
                        <TextInput
                            id='password'
                            type={showPass ? 'text' : 'password'}
                            label='Change Password'
                            name='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start" tabIndex={0}>
                                    <LockIcon width={22} height={22} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end" tabIndex={-1}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPass(!showPass)}
                                        edge="end"
                                    >
                                        {showPass ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className='d-flex col-2 pl-4 align-self-end'>
                        <Button
                            variant='medium'
                            disabled={!validateForm()}
                            label='Save'
                            onClick={handleSave}
                        />
                    </div>
                </div>
                <div className='row mt-3 mb-4 pl-2'>
                    <div className='d-flex col-12 pl-4'>
                        <h4 style={{ color: '#60606A' }}>App Settings</h4>
                    </div>
                    <div className='d-flex col-3 pl-4 pt-3 align-items-baseline justify-content-between'>
                        <div className='d-flex'>
                            <NotificationIcon width={22} height={22} />
                            <p className='pl-2'>Notifications</p>
                        </div>
                        <Switch
                            disabled={loading}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            name="notificationEnabled"
                            checked={notificationEnabled}
                            onChange={handleNotifySave}
                        />
                    </div>
                </div>
                <div className='row mt-3 pl-2'>
                    <div className='d-flex col-6 pl-4'>
                        <h4 style={{ color: '#60606A' }}>User Decision</h4>
                    </div>
                    <div className='col-12 pl-4 pt-3'>
                        <ButtonBase onClick={handleLogout}>
                            <LogoutIcon width={22} height={22} />
                            <span className='d-flex align-content-center pl-2'>
                                Logout
                            </span>
                        </ButtonBase>
                    </div>
                </div>
            </div>
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

import axios from "axios"
import {
    addGlassesURL,
    getAllGlassesURL,
    deleteGlassesURL
} from "../../API/endpoints"

export function addGlassesSample(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getGlassesSampleData
) {
    setLoading(true)
    axios
        .post(addGlassesURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("A new glasses sample has been added successfully !");
                getGlassesSampleData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add a new glasses sample at the moment.");
        });
}

export function getGlassesSamples(
    setGlassesData,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    axios.get(getAllGlassesURL)
        .then((res) => {
            if (res.data && res.data.success) {
                setGlassesData(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Glasses data at the moment.");
        })
}

export function removeGlassesSample(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getGlassesSampleData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteGlassesURL + "/" + e._id)
        .then((res) => {
            // console.log("response", res)
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Sample data deleted successfully !`);
                getGlassesSampleData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete this sample at the moment.");
        })
}
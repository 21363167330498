const snackbarContentStyle = {
  root: {
    flexWrap: "unset",
    position: "relative",
    padding: "20px 15px",
    marginBottom: "20px",
    fontSize: "14px",
    borderRadius: "10px"
  },
  top20: {
    top: "10px"
  },
  top40: {
    top: "20px"
  },
  info: {
    backgroundColor: 'blue',
    color: 'white'
  },
  success: {
    backgroundColor: "green",
    color: 'white'
  },
  warning: {
    backgroundColor: 'yellow',
    color: 'white'
  },
  danger: {
    backgroundColor: 'red',
    color: 'white'
  },
  primary: {
    backgroundColor: 'blue',
    color: 'white'
  },
  rose: {
    backgroundColor: 'red',
    color: 'white'
  },
  message: {
    padding: "0",
    display: "block"
  },
  close: {
    width: "11px",
    height: "11px"
  },
  iconButton: {
    width: "24px",
    height: "24px",
    padding: "0px"
  },
  icon: {
    display: "block",
    left: "15px",
    position: "absolute",
    top: "50%",
    marginTop: "-15px",
    width: "30px",
    height: "30px"
  },
  iconMessage: {
    paddingLeft: "50px",
    display: "block"
  },
  actionRTL: {
    marginLeft: "-8px",
    marginRight: "auto"
  }
};

export default snackbarContentStyle;

import axios from "axios"
import {
    // **** Face API points
    addFaceMakeUpURL,
    getAllFaceMakeUpsURL,
    updateFaceMakeUpURL,
    deleteFaceMakeUpURL,
    // ****  Nose API Points
    addNoseMakeUpURL,
    getAllNoseMakeUpsURL,
    updateNoseMakeUpURL,
    deleteNoseMakeUpURL,
    // ****  Eyes API Points
    addEyesMakeUpURL,
    updateEyesMakeUpURL,
    getAllEyesMakeUpsURL,
    deleteEyesMakeUpURL,
    // ****  Lips API Points
    addLipsMakeUpURL,
    updateLipsMakeUpURL,
    getAllLipsMakeUpsURL,
    deleteLipsMakeUpURL,
    // ****  Haircut API Points
    addHairCutURL,
    updateHairCutURL,
    getAllHairCutsURL,
    deleteHairCutURL,
    // ****  Jewelry API Points
    addJewelryURL,
    updateJewelryURL,
    getAllJewelriesURL,
    deleteJewelryURL
} from "../../API/endpoints"

export function addMakeupTip(
    typeSelected,
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getMakeupTipsData
) {
    setLoading(true)
    let url
    if (typeSelected === 'Face Makeup') {
        url = addFaceMakeUpURL
    } else if (typeSelected === 'Nose Makeup') {
        url = addNoseMakeUpURL
    } else if (typeSelected === 'Eyes Makeup') {
        url = addEyesMakeUpURL
    } else if (typeSelected === 'Lips Makeup') {
        url = addLipsMakeUpURL
    } else if (typeSelected === 'Haircut & Style') {
        url = addHairCutURL
    } else if (typeSelected === 'Jewelry') {
        url = addJewelryURL
    }
    axios
        .post(url, formData)
        .then((res) => {
            // console.log("response after adding : ", res.data);
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg(`${typeSelected} has been added successfully !`);
                getMakeupTipsData(typeSelected)
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getMakeupTipsData(typeSelected)
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg(`Cannot add a new ${typeSelected} at the moment.`);
            getMakeupTipsData(typeSelected)
        });
}

export function getMakeupTips(
    typeSelected,
    setMakeupTipsData,
    setLoading,
    setOpenNotification,
    setErrorMsg,
) {
    let url
    if (typeSelected === 'Face Makeup') {
        url = getAllFaceMakeUpsURL
    } else if (typeSelected === 'Nose Makeup') {
        url = getAllNoseMakeUpsURL
    } else if (typeSelected === 'Eyes Makeup') {
        url = getAllEyesMakeUpsURL
    } else if (typeSelected === 'Lips Makeup') {
        url = getAllLipsMakeUpsURL
    } else if (typeSelected === 'Haircut & Style') {
        url = getAllHairCutsURL
    } else if (typeSelected === 'Jewelry') {
        url = getAllJewelriesURL
    }
    axios.get(url)
        .then((res) => {
            if (res.data && res.data.success) {
                // console.log(typeSelected, " : ", res.data.data)
                setMakeupTipsData(res.data.data)
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                setLoading(false)
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg(`Cannot get ${typeSelected} at the moment.`);
            setLoading(false)
        })
}

export function removeMakeupTip(
    e,
    typeSelected,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getMakeupTipsData,
    onCloseConfirmDelete
) {
    setLoading(true)
    let url
    if (typeSelected === 'Face Makeup') {
        url = deleteFaceMakeUpURL
    } else if (typeSelected === 'Nose Makeup') {
        url = deleteNoseMakeUpURL
    } else if (typeSelected === 'Eyes Makeup') {
        url = deleteEyesMakeUpURL
    } else if (typeSelected === 'Lips Makeup') {
        url = deleteLipsMakeUpURL
    } else if (typeSelected === 'Haircut & Style') {
        url = deleteHairCutURL
    } else if (typeSelected === 'Jewelry') {
        url = deleteJewelryURL
    }
    axios.delete(url + "/" + e._id)
        .then((res) => {
            // console.log("response", res)
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`${typeSelected} deleted successfully !`);
                getMakeupTipsData(typeSelected)
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getMakeupTipsData(typeSelected)
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg(`Cannot delete ${typeSelected} at the moment.`);
            getMakeupTipsData(typeSelected)
        })
}

export function updateMakeupTip(
    typeSelected,
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getMakeupTipsData
) {
    setLoading(true)
    let url
    if (typeSelected === 'Face Makeup') {
        url = updateFaceMakeUpURL
    } else if (typeSelected === 'Nose Makeup') {
        url = updateNoseMakeUpURL
    } else if (typeSelected === 'Eyes Makeup') {
        url = updateEyesMakeUpURL
    } else if (typeSelected === 'Lips Makeup') {
        url = updateLipsMakeUpURL
    } else if (typeSelected === 'Haircut & Style') {
        url = updateHairCutURL
    } else if (typeSelected === 'Jewelry') {
        url = updateJewelryURL
    }
    axios
        .put(url, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg(`${typeSelected} has been updated successfully !`);
                getMakeupTipsData(typeSelected)
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getMakeupTipsData(typeSelected)
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg(`Cannot update ${typeSelected} at the moment.`);
            getMakeupTipsData(typeSelected)
        });
}
import axios from "axios"
import {
    addValueContrastURL,
    getAllValueContrastURL,
    deleteValueContrastURL,
    updateValueContrastURL
} from "../../API/endpoints"

export function addValueContrast(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getValueContrastData
) {
    setLoading(true)
    axios
        .post(addValueContrastURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Value Contrast has been added successfully !");
                getValueContrastData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getValueContrastData()
            }
        })
        .catch((e) => {
            console.log("catch error : ", e);
            setOpenNotification(true);
            setErrorMsg("Cannot add Value Contrast at the moment.");
            getValueContrastData()
        });
}

export function getValueContrast(
    tabName,
    setMode,
    setId,
    setMultipleImages,
    setMultipleImageData,
    setTravelGuideBody,
    setLoading,
    setOpenNotification,
    setErrorMsg
) {
    setLoading(true)
    axios.get(getAllValueContrastURL)
        .then((res) => {
            if (res.data && res.data.success) {
                res.data.data = res.data.data.filter(d => d.type === tabName)
                if (res.data.data.length > 0) {
                    setMode('edit')
                    setTravelGuideBody(res.data.data[0].paragraphText)
                    setMultipleImageData(res.data.data[0].images)
                    setMultipleImages(res.data.data[0].images)
                    setId(res.data.data[0]._id)
                }
                else {
                    setMode('add')
                    setTravelGuideBody('')
                    setMultipleImageData([])
                    setMultipleImages([])
                    setId('')
                }
                setLoading(false)
            }
            else {
                setErrorMsg(res.error);
                setOpenNotification(true);
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot get Value Contrasts at the moment.");
        })
}

export function removeValueContrast(
    e,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getValueContrastData,
    onCloseConfirmDelete
) {
    setLoading(true)
    axios.delete(deleteValueContrastURL + "/" + e)
        .then((res) => {
            if (res.data && res.data.success) {
                onCloseConfirmDelete()
                setOpenNotification(true);
                setsuccessMsg(`Value Contrast deleted successfully !`);
                getValueContrastData()
            } else {
                onCloseConfirmDelete()
                setErrorMsg(res.error);
                setOpenNotification(true);
                getValueContrastData()
            }
        })
        .catch((e) => {
            onCloseConfirmDelete()
            setOpenNotification(true);
            setErrorMsg("Cannot delete Value Contrast at the moment.");
            getValueContrastData()
        })
}

export function updateValueContrast(
    formData,
    setOpenNotification,
    setErrorMsg,
    setsuccessMsg,
    setLoading,
    getValueContrastData
) {
    setLoading(true)
    axios
        .put(updateValueContrastURL, formData)
        .then((res) => {
            if (res.data && res.data.success) {
                setOpenNotification(true);
                setsuccessMsg("Value Contrast has been updated successfully !");
                getValueContrastData()
            } else {
                setErrorMsg(res.error);
                setOpenNotification(true);
                getValueContrastData()
            }
        })
        .catch((e) => {
            setOpenNotification(true);
            setErrorMsg("Cannot update Value Contrast at the moment.");
            getValueContrastData()
        });
}
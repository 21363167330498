import React, { useEffect, useState, useCallback } from 'react'
import ImageViewer from "react-simple-image-viewer";
import MultipleImageInput from '../../components/MultipleImageInput/MultipleImageInput'
import Notification from "../../components/Notification/Notification";
import TextEditor from '../../components/TextEditor/TextEditor';
import Button from '../../components/Button/Button'
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'
import { uploadsUrl } from '../../API/endpoints';
import {
    addTravelTip,
    updateTravelTip,
    removeTravelTip,
    getTravelTip
} from './networkCalls'
import Loader from 'react-loader-spinner';
import { colors } from '../../constants/theme';
import HollowButton from '../../components/HollowButton/HollowButton';

export default function TravelTips() {

    const [mode, setMode] = useState('add')
    const [id, setId] = useState('')
    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [travelGuideBody, setTravelGuideBody] = useState('');
    // Image Viewer variables
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imagesDisplay, setImagesDisplay] = useState([]);
    const [multipleImageData, setMultipleImageData] = useState([]) // For Preview
    const [multipleImages, setMultipleImages] = useState([]) // For storing the images data
    // Variables for delete
    const [openConfirmation, setOpenConfirmation] = useState(false)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getTravelTipData()
    }, [])

    function getTravelTipData() {
        getTravelTip(
            setMode,
            setId,
            setMultipleImages,
            setMultipleImageData,
            setTravelGuideBody,
            setLoading,
            setOpenNotification,
            setErrorMsg,
        )
    }

    function toArray(fileList) {
        return Array.prototype.slice.call(fileList);
    }

    const handleMultipleFileChange = (event) => {
        var file = event.target.files;
        var joined = toArray(multipleImages).concat(toArray(file))
        setMultipleImages(joined)

        let arr = [...multipleImageData]
        for (let i = 0; i < file.length; i++) {
            let reader = new FileReader();
            reader.readAsDataURL(file[i]);
            reader.onload = function (event) {
                arr.push(event.target.result)
                setMultipleImageData([...arr])
            }
        }
    };

    const onImageDelete = (index) => {
        let temp = [...multipleImageData]
        temp.splice(index, 1)
        setMultipleImageData(temp)
        let tempImages = [...multipleImages]
        tempImages.splice(index, 1)
        setMultipleImages(tempImages)
    }

    const openImageViewer = useCallback((index, val) => {
        let newImage = [];

        val.map((item, i) => {
            if (item.includes("\\")) {
                return newImage.push(uploadsUrl + item.split('\\')[1] + '/' + item.split("\\")[2]);
            }
            else if (item.includes("/")) {
                return newImage.push(uploadsUrl + item);
            } 
            else {
                return newImage.push(item)
            }
        });
        setImagesDisplay(newImage)
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        setImagesDisplay([])
    };

    function validateForm() {
        if (
            travelGuideBody !== '' &&
            multipleImages.length > 0
        ) {
            return true
        }
        else {
            return false
        }
    }

    const handleSave = () => {
        const data = {
            paragraphText: travelGuideBody
        }
        let formData = new FormData();
        if (multipleImages) {
            for (var x = 0; x < multipleImages.length; x++) {
                formData.append("files", multipleImages[x], multipleImages[x].name);
            }
        }
        formData.append("data", JSON.stringify(data));
        addTravelTip(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getTravelTipData
        )
    }

    const handleEdit = () => {
        const data = {
            id,
            paragraphText: travelGuideBody
        }
        let formData = new FormData();
        if (multipleImages && multipleImages.length > 0) {
            for (var x = 0; x < multipleImages.length; x++) {
                if (multipleImages[x].name) {
                    formData.append("newImages", multipleImages[x], multipleImages[x].name);
                }
                else {
                    formData.append("oldImages", multipleImages[x]);
                }
            }
        }
        formData.append("data", JSON.stringify(data));
        updateTravelTip(
            formData,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getTravelTipData
        )
    }

    const onCloseConfirmDelete = () => {
        setOpenConfirmation(false)
        setId('')
    }

    const onConfirmDelete = (e) => {
        removeTravelTip(
            e,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getTravelTipData,
            onCloseConfirmDelete
        )
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='d-flex col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Travel Guide</h2>
                        <span className='d-flex pl-4 pt-1'>
                            {loading && <Loader type='Grid' color={colors.button} height={25} width={25} />}
                        </span>
                    </div>
                </div>
                <div className='row mt-3 pr-4'>
                    <div className='col-12 pl-4 mt-3 pr-4'>
                        <MultipleImageInput
                            onMultipleInputClick={handleMultipleFileChange}
                            multipleImageData={multipleImageData}
                            openImageViewer={openImageViewer}
                            onImageDelete={onImageDelete}
                        />
                    </div>
                </div>
                <div className='row mt-3 pr-4'>
                    <div className='col-12 pl-4 mt-3 pr-4'>
                        <TextEditor
                            setBody={setTravelGuideBody}
                            value={travelGuideBody}
                        />
                    </div>
                </div>
                <div className='row mt-3 pr-4'>
                    <div className='col-12 pl-4 mt-3 pr-4 d-flex justify-content-end'>
                        {mode === 'edit' &&
                            <span className='mr-2'>
                                <HollowButton
                                    variant='medium'
                                    label={`Delete`}
                                    onClick={() => setOpenConfirmation(true)}
                                />
                            </span>
                        }
                        <Button
                            disabled={!validateForm()}
                            variant='medium'
                            label={`Save`}
                            onClick={() => mode === 'add' ? handleSave() : handleEdit()}
                        />
                    </div>
                </div>
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={imagesDisplay}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: 99
                    }}
                />
            )}
            <ConfirmationDialog
                selectedType={id}
                openConfirmation={openConfirmation}
                onClickDone={onConfirmDelete}
                onClickCancel={onCloseConfirmDelete}
            />
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

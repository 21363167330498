import React, { useEffect, useState, useCallback } from 'react'
import Loader from 'react-loader-spinner'
import { colors } from '../../constants/theme'
import { ReactComponent as BodyProportions } from "../../assets/icons/svg/BodyProportions.svg"
import { ReactComponent as BodyShape } from "../../assets/icons/svg/BodyShape.svg"
import { ReactComponent as ColourContrast } from "../../assets/icons/svg/ColourContrast.svg"
import { ReactComponent as CoolUndertone } from "../../assets/icons/svg/CoolUndertone.svg"
import { ReactComponent as FaceShape } from "../../assets/icons/svg/FaceShape.svg"
import { ReactComponent as StyleIcon } from "../../assets/icons/svg/StyleIcon.svg"
import { ReactComponent as SurveysIcon } from "../../assets/icons/svg/Surveys.svg"
import { ReactComponent as ValueContrast } from "../../assets/icons/svg/ValueContrast.svg"
import { ReactComponent as WarmUndertone } from "../../assets/icons/svg/WarmUndertone.svg"
import {
    updateQuiz,
    getQuizes
} from './networkCalls'
import './surveys.css'
import Notification from "../../components/Notification/Notification";
import AddEditModal from './addEditModal'

const SECTIONS = [
    { label: 'Surveys', img: <SurveysIcon width={42} height={42} />, value: 'surveys' },
    { label: 'Value Contrast', img: <ValueContrast width={42} height={42} />, value: 'value_contrast' },
    { label: 'Colour Contrast', img: <ColourContrast width={42} height={42} />, value: 'colour_contrast' },
    { label: 'Style Personality', img: <StyleIcon width={42} height={42} />, value: 'style_personality' },
    { label: 'Warm Undertone', img: <WarmUndertone width={42} height={42} />, value: 'warm_undertone' },
    { label: 'Cool Undertone', img: <CoolUndertone width={42} height={42} />, value: 'cool_undertone' },
    { label: 'Body Shape', img: <BodyShape width={42} height={42} />, value: 'body_shape' },
    { label: 'Face Shape', img: <FaceShape width={42} height={42} />, value: 'face_shape' },
    { label: 'Body Proportions', img: <BodyProportions width={42} height={42} />, value: 'body_proportions' }
]

export default function Surveys() {

    const [openNotification, setOpenNotification] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [successMsg, setsuccessMsg] = useState('')
    const [loading, setLoading] = useState(true)
    const [openLinkModal, setOpenLinkModal] = useState(false)
    const [modalLink, setModalLink] = useState(null)
    const [sectionsArr, setSectionsArr] = useState(SECTIONS)

    if (openNotification) {
        setTimeout(() => {
            setOpenNotification(false);
            setErrorMsg("");
            setsuccessMsg("");
        }, 4000);
    }

    useEffect(() => {
        getQuizesData()
    }, [])

    const setQuizData = (e) => {
        for (let j = 0; j < e.length; j++) {
            let item = e[j].linkFor
            for (let i = 0; i < SECTIONS.length; i++) {
                let item1 = SECTIONS[i].value
                if (item === item1) {
                    SECTIONS[i].link = e[j].link
                }
            }
        }
    }

    function getQuizesData() {
        getQuizes(
            setQuizData,
            setLoading,
            setOpenNotification,
            setErrorMsg
        )
    }

    const handleSectionClick = (e) => {
        setOpenLinkModal(true)
        setModalLink(e)
    }

    const handleCloseModal = () => {
        setOpenLinkModal(false)
        setModalLink(null)
    }

    const handleSubmitLink = (data) => {
        updateQuiz(
            data,
            setOpenNotification,
            setErrorMsg,
            setsuccessMsg,
            setLoading,
            getQuizesData
        )
    }

    const renderSectionCard = (item, index) => {
        return (
            <div key={index}
                className='col cardDesign'
                onClick={() => handleSectionClick(item)}
            >
                {item.img}
                <p style={{ fontSize: '18px', fontWeight: 500, marginTop: 10 }}>
                    {item.label}
                </p>
            </div>
        );
    }

    return (
        <div>
            <div className='container-fluid mainPage'>
                <div className='row mt-3 pl-2'>
                    <div className='d-flex col-6 pl-4'>
                        <h2 style={{ fontWeight: '700' }}>Quizzes / Questions</h2>
                        <span className='d-flex pl-4 pt-1'>
                            {loading && <Loader type='Grid' color={colors.button} height={25} width={25} />}
                        </span>
                    </div>
                </div>
                <div className='row mt-5 pl-4 pr-4'>
                    {sectionsArr.map((item, index) => renderSectionCard(item, index))}
                </div>
            </div>
            {/* {openLinkModal && */}
            <AddEditModal
                open={openLinkModal}
                headerText={`Add Link For ${modalLink?.label ?? 'this'}`}
                labelText={`Submit`}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitLink}
                selectedItem={modalLink}
            />
            {/* } */}
            <Notification
                msg={errorMsg}
                open={openNotification}
                success={successMsg}
            />
        </div>
    )
}

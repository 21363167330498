import './App.css';
import { Router, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import cookie from 'react-cookies'
// Import the Screen views
import Login from './views/Login/Login'
import ForgetPassword from './views/Login/ForgetPassword'
// import the routing of app where screens are shown
import HomeRoutes from './routing/home'

const hist = createBrowserHistory()

function SecuredRoute(props) {
  const { component: Component, path } = props
  return (
    <Router history={hist}>
      <Route
        path={path}
        render={() => {
          const user = cookie.load('user') || ''
          
          if (user.name) {
            return <Component />
          } else {
            return <Redirect to='/login' />
          }
        }}
      />
    </Router>
  )
}

function App() {

  return (
    <Router history={hist}>
      <SecuredRoute exact path='/dashboard' component={HomeRoutes} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/' component={Login} />
      <Route exact path='/forgetpassword' component={ForgetPassword} />
    </Router>
  );
}

export default App;